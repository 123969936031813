"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSolanaGateway = exports.chainImplementation = void 0;
const solana_gateway_ts_1 = require("@identity.com/solana-gateway-ts");
const common_gateway_react_1 = require("@civic/common-gateway-react");
const web3_js_1 = require("@solana/web3.js");
const prove_solana_wallet_1 = require("@identity.com/prove-solana-wallet");
const config_1 = require("./config");
const version_1 = require("./version");
const createSolanaTransactionFromBase64 = (partiallySignedTx) => web3_js_1.Transaction.from(Buffer.from(partiallySignedTx, 'base64'));
const chainImplementation = ({ clusterUrl, cluster, publicKey, signTransaction, handleTransaction, gatekeeperNetworkAddress, stage, }) => {
    common_gateway_react_1.logger.debug('Connecting to cluster with commitment recent', clusterUrl);
    const connection = new web3_js_1.Connection(clusterUrl, 'processed');
    const requiresSignature = (transaction) => {
        return transaction.signatures.find((sig) => sig.publicKey.equals(publicKey)) !== undefined;
    };
    const submitTransaction = async (transaction) => {
        const txSig = await connection.sendRawTransaction(transaction.serialize());
        // TODO re-enable when https://github.com/solana-labs/solana/issues/26198 is fixed
        // const blockhash = await connection.getLatestBlockhash();
        // await connection.confirmTransaction({ ...blockhash, signature: txSig });
        await connection.confirmTransaction(txSig);
    };
    return {
        addOnGatewayTokenChangeListener: async (gatewayToken, tokenDidChange) => {
            return Promise.resolve((0, solana_gateway_ts_1.onGatewayTokenChange)(connection, new web3_js_1.PublicKey(gatewayToken.identifier), (token) => {
                tokenDidChange({
                    issuingGatekeeper: token.issuingGatekeeper.toBase58(),
                    gatekeeperNetworkAddress: token.gatekeeperNetwork.toBase58(),
                    owner: token.owner.toBase58(),
                    state: common_gateway_react_1.State[token.state],
                    identifier: token.publicKey.toBase58(),
                    expiryTime: token.expiryTime,
                });
            }));
        },
        removeOnGatewayTokenChangeListener: (listenerId) => {
            (0, solana_gateway_ts_1.removeAccountChangeListener)(connection, listenerId);
        },
        findGatewayToken: async () => {
            const onChainToken = await (0, solana_gateway_ts_1.findGatewayToken)(connection, publicKey, new web3_js_1.PublicKey(gatekeeperNetworkAddress));
            if (!onChainToken)
                return undefined;
            return {
                issuingGatekeeper: onChainToken.issuingGatekeeper.toBase58(),
                gatekeeperNetworkAddress: onChainToken.gatekeeperNetwork.toBase58(),
                owner: onChainToken.owner.toBase58(),
                state: common_gateway_react_1.State[onChainToken.state],
                identifier: onChainToken.publicKey.toBase58(),
                expiryTime: onChainToken.expiryTime,
            };
        },
        proveWalletOwnership: async () => {
            const result = await (0, prove_solana_wallet_1.prove)(publicKey, signTransaction, (0, config_1.makeConfig)(clusterUrl, cluster));
            return result.toString('base64');
        },
        handleUserSignedTransaction: async (partiallySignedTx) => {
            const transaction = createSolanaTransactionFromBase64(partiallySignedTx);
            if (handleTransaction) {
                await handleTransaction(transaction);
                return;
            }
            let transactionToSend = transaction;
            if (requiresSignature(transaction)) {
                transactionToSend = await signTransaction(transaction);
            }
            await submitTransaction(transactionToSend);
        },
        chainType: common_gateway_react_1.ChainType.SOLANA,
        httpConfig: {
            baseUrl: (0, config_1.getGatekeeperEndpoint)(stage),
            queryParams: { network: cluster },
            headers: { 'X-Civic-Client': version_1.VERSION },
        },
    };
};
exports.chainImplementation = chainImplementation;
const useSolanaGateway = () => {
    const { gatewayToken, gatewayTokenTransaction } = (0, common_gateway_react_1.useGateway)();
    const solanaGatewayToken = gatewayToken
        ? {
            issuingGatekeeper: new web3_js_1.PublicKey(gatewayToken.issuingGatekeeper),
            gatekeeperNetworkAddress: new web3_js_1.PublicKey(gatewayToken.gatekeeperNetworkAddress),
            owner: new web3_js_1.PublicKey(gatewayToken.owner),
            state: gatewayToken.state,
            publicKey: new web3_js_1.PublicKey(gatewayToken.identifier),
            expiryTime: gatewayToken.expiryTime,
        }
        : undefined;
    const solanaGatewayTokenTransaction = gatewayTokenTransaction
        ? createSolanaTransactionFromBase64(gatewayTokenTransaction)
        : undefined;
    return Object.assign(Object.assign({}, (0, common_gateway_react_1.useGateway)()), { gatewayToken: solanaGatewayToken, gatewayTokenTransaction: solanaGatewayTokenTransaction });
};
exports.useSolanaGateway = useSolanaGateway;
