export function memoize<a>(fn: (...args: any[]) => a) {
  let cache: a | undefined = undefined;

  return function(...args: any[]) {
    if (cache) return cache;

    const result = fn(...args);
    cache = result;
    return result;
  }
}

export function memoizeAsync<a>(fn: (...args: any[]) => Promise<a>) {
  let cache: a | undefined = undefined;

  return async function(...args: any[]) {
    if (cache) return cache;

    const result = await fn(...args);
    cache = result;
    return result;
  }
}