import * as anchor from "@project-serum/anchor";
import styled from "styled-components";
import Button from "@mui/material/Button";
import { CandyMachineAccount } from "./candy-machine";
import { CircularProgress } from "@mui/material";
import { GatewayStatus, useGateway } from "@civic/solana-gateway-react";
import { useEffect, useState, useRef, useMemo } from "react";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import {
  findGatewayToken,
  getGatewayTokenAddressForOwnerAndGatekeeperNetwork,
  onGatewayTokenChange,
  removeAccountChangeListener,
} from "@identity.com/solana-gateway-ts";
import {
  createClaimTransactionsIfNeeded,
  isWalletInWhitelist,
} from "./gumdrop/gumdrop";

export const CTAButton = styled(Button)`
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #604ae5 0%, #813eee 100%);
  color: white;
  font-size: 16px;
  font-weight: bold;
`; // add your own styles here

export const MintButton = ({
  onMint,
  candyMachine,
  gumdropProgram,
  isMinting,
  setIsMinting,
  isActive,
}: {
  onMint: () => Promise<void>;
  candyMachine?: CandyMachineAccount;
  gumdropProgram?: anchor.Program;
  isMinting: boolean;
  setIsMinting: (val: boolean) => void;
  isActive: boolean;
}) => {
  const anchorWallet = useAnchorWallet() as anchor.Wallet;
  const connection = useConnection();
  const [verified, setVerified] = useState(false);
  const { requestGatewayToken, gatewayStatus } = useGateway();
  const [webSocketSubscriptionId, setWebSocketSubscriptionId] = useState(-1);
  const [clicked, setClicked] = useState(false);
  const [mintButtonContent, setMintButtonContent] = useState("LOADING...");
  
  const getMintButtonContent = async () => {
    if (!candyMachine || !anchorWallet || !gumdropProgram) {
      setMintButtonContent("LOADING...");
      return;
    }

    if (candyMachine.state.isSoldOut) {
      setMintButtonContent("SOLD OUT");
      return;
    }

    if (candyMachine.state.isPresale || candyMachine.state.isWhitelistOnly) {
      // Presale or Whitelist
      const whitelistMint = candyMachine.state.whitelistMintSettings?.mint;
      if (whitelistMint) {
        try {
          // already have token balance
          const ata = await anchor.utils.token.associatedAddress({mint: whitelistMint, owner: anchorWallet.publicKey});
          const balance = await candyMachine.program.provider.connection.getTokenAccountBalance(ata);
          if (balance && parseInt(balance.value.amount) > 0) {
            setMintButtonContent("CLAIM NFT");
            return;
          }
        } catch (e) {
          // not have token balance
        }
        

        // is in allowlist or not?
        if (await isWalletInWhitelist(gumdropProgram, anchorWallet.publicKey)) {
          // in allowlist
          // @ts-ignore
          const rpcEndpoint = candyMachine?.program.provider.connection._rpcEndpoint;
          const canClaim = await createClaimTransactionsIfNeeded(
            anchorWallet,
            gumdropProgram,
            candyMachine?.id,
            rpcEndpoint
          );
          setMintButtonContent(canClaim ? "CLAIM NFT" : "ALREADY CLAIMED NFT");
          return;
        } else {
          // not in allowlist
          setMintButtonContent("NOT IN ALLOWLIST");
          return;
        } 
      }
    }
    
    // Everything is perfect
    setMintButtonContent("CLAIM NFT");
    return;
  };

  useEffect(() => {
    getMintButtonContent();
  }, [candyMachine, gumdropProgram, anchorWallet]);

  useEffect(() => {
    const mint = async () => {
      await removeAccountChangeListener(
        connection.connection,
        webSocketSubscriptionId
      );
      await onMint();

      setClicked(false);
      setVerified(false);
    };
    if (verified && clicked) {
      mint();
    }
  }, [
    verified,
    clicked,
    connection.connection,
    onMint,
    webSocketSubscriptionId,
  ]);

  const previousGatewayStatus = usePrevious(gatewayStatus);
  useEffect(() => {
    const fromStates = [
      GatewayStatus.NOT_REQUESTED,
      GatewayStatus.REFRESH_TOKEN_REQUIRED,
    ];
    const invalidToStates = [...fromStates, GatewayStatus.UNKNOWN];
    if (
      fromStates.find((state) => previousGatewayStatus === state) &&
      !invalidToStates.find((state) => gatewayStatus === state)
    ) {
      setIsMinting(true);
    }
    // console.log("change: ", gatewayStatus);
  }, [setIsMinting, previousGatewayStatus, gatewayStatus]);

  return (
    <CTAButton
      disabled={isMinting || !isActive}
      onClick={async () => {
        if (candyMachine?.state.isActive && candyMachine?.state.gatekeeper) {
          const network =
            candyMachine.state.gatekeeper.gatekeeperNetwork.toBase58();
          if (network === "ignREusXmGrscGNUesoU9mxfds9AiYTezUKex2PsZV6") {
            if (gatewayStatus === GatewayStatus.ACTIVE) {
              await onMint();
            } else {
              // setIsMinting(true);
              await requestGatewayToken();
              console.log("after: ", gatewayStatus);
            }
          } else if (
            network === "ttib7tuX8PTWPqFsmUFQTj78MbRhUmqxidJRDv4hRRE" ||
            network === "tibePmPaoTgrs929rWpu755EXaxC7M3SthVCf6GzjZt"
          ) {
            setClicked(true);
            const gatewayToken = await findGatewayToken(
              connection.connection,
              anchorWallet?.publicKey!,
              candyMachine.state.gatekeeper.gatekeeperNetwork
            );

            if (gatewayToken?.isValid()) {
              await onMint();
            } else {
              window.open(
                `https://verify.encore.fans/?gkNetwork=${network}`,
                "_blank"
              );

              const gatewayTokenAddress =
                await getGatewayTokenAddressForOwnerAndGatekeeperNetwork(
                  anchorWallet?.publicKey!,
                  candyMachine.state.gatekeeper.gatekeeperNetwork
                );

              setWebSocketSubscriptionId(
                onGatewayTokenChange(
                  connection.connection,
                  gatewayTokenAddress,
                  () => setVerified(true),
                  "confirmed"
                )
              );
            }
          } else {
            setClicked(false);
            throw new Error(`Unknown Gatekeeper Network: ${network}`);
          }
        } else {
          await onMint();
          setClicked(false);
        }
      }}
      variant="contained"
    >
      {isMinting ? <CircularProgress /> : mintButtonContent}
    </CTAButton>
  );
};

function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
