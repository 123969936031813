[
    {
        "handle": "AW9aH2R63pQ9Q7uqtYj6AE7ca15WojzNwmRXMFKSkev4",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=AW9aH2R63pQ9Q7uqtYj6AE7ca15WojzNwmRXMFKSkev4&amount=1&index=0&proof=E2mkPL3RHhTzWEHKnBmC5j3aUNxFPWbaNQp3fuhtCSZb,7Pp1mCs4M3JBgJoq3zQmL6V3ZgruutEz1YFZHqxPjkPr,CnBC17qpHJ9uku1zCBwipvduxfyHqNukTHyW6pQJaUF,FavYcYDCiASauMGa3i6Bw5wXCygkEdkLk9w3fUD2JY8Z,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "7UVwdL9jfezTE6odM14cY3eiJNfqCNXMaH716525SHPe",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=7UVwdL9jfezTE6odM14cY3eiJNfqCNXMaH716525SHPe&amount=1&index=1&proof=AfmVbyW9v13ounieYZspN1jdfGhVStmVSdM4cCMDLQtk,7Pp1mCs4M3JBgJoq3zQmL6V3ZgruutEz1YFZHqxPjkPr,CnBC17qpHJ9uku1zCBwipvduxfyHqNukTHyW6pQJaUF,FavYcYDCiASauMGa3i6Bw5wXCygkEdkLk9w3fUD2JY8Z,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "EBRgjDzJwyZGm1iozjb6zSb3E4s1DBqF787t8w8aVr4x",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=EBRgjDzJwyZGm1iozjb6zSb3E4s1DBqF787t8w8aVr4x&amount=1&index=2&proof=4SPyDsnjLP2oSLNk2aUfWe2Ax5K3z4n8JCHKSCNfQkqE,BhVX3hevLTdC9xavR8b6AD8gcF43vUsJHptLsVxzdEz3,CnBC17qpHJ9uku1zCBwipvduxfyHqNukTHyW6pQJaUF,FavYcYDCiASauMGa3i6Bw5wXCygkEdkLk9w3fUD2JY8Z,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "AKNiVpJ18jeRXzNWYeDKocwiWwnK7en7cXvvnJNtFkgV",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=AKNiVpJ18jeRXzNWYeDKocwiWwnK7en7cXvvnJNtFkgV&amount=1&index=3&proof=BE6Lj3fc4oMkKYLZHza2CLm1W3UNjpm7xWhGYBPJ9UBN,BhVX3hevLTdC9xavR8b6AD8gcF43vUsJHptLsVxzdEz3,CnBC17qpHJ9uku1zCBwipvduxfyHqNukTHyW6pQJaUF,FavYcYDCiASauMGa3i6Bw5wXCygkEdkLk9w3fUD2JY8Z,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "GR3GcHBjhr8u3p41zkyh1qJjHRC857Lmpogxbfg3AMB",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=GR3GcHBjhr8u3p41zkyh1qJjHRC857Lmpogxbfg3AMB&amount=1&index=4&proof=F2pDUBwqmpxYnUE9jnk13zzgV1fw9DpQ9ERqgnGYqbWN,5oCWco8iGTg28JMgxShu3jmg58g3zAsJX3Xx6TLgjECG,7ofgfSWU2k8LwGfmPiMh8HDHC7nMt7sA3CEMbQqzdyYp,FavYcYDCiASauMGa3i6Bw5wXCygkEdkLk9w3fUD2JY8Z,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "J7XCYuGwK9WdYZa9SUVjT6KEPbvgp238SMV1r4SBN4gb",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=J7XCYuGwK9WdYZa9SUVjT6KEPbvgp238SMV1r4SBN4gb&amount=1&index=5&proof=N4f2G9JJTXFTTwfqXJG7MMaHrydWyVi6YbxG6ekKbKL,5oCWco8iGTg28JMgxShu3jmg58g3zAsJX3Xx6TLgjECG,7ofgfSWU2k8LwGfmPiMh8HDHC7nMt7sA3CEMbQqzdyYp,FavYcYDCiASauMGa3i6Bw5wXCygkEdkLk9w3fUD2JY8Z,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "BAHKWsAHWvSkV1coFk2t5jmajJsEKDzuRaPaQdGtQ9p3",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=BAHKWsAHWvSkV1coFk2t5jmajJsEKDzuRaPaQdGtQ9p3&amount=1&index=6&proof=D9FgivqEH56ybENEZ5ntdYwcT8XEFeo5QcoYgH1pftbL,AbfcJGqX7VYbC9qYpfbqzyyMYJ7aQioVUSF9oP4rYwip,7ofgfSWU2k8LwGfmPiMh8HDHC7nMt7sA3CEMbQqzdyYp,FavYcYDCiASauMGa3i6Bw5wXCygkEdkLk9w3fUD2JY8Z,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "4rH7gRYdRpF1NtZMXbQHQ4wHNeEN9TNj4ZtRfbBCPePp",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=4rH7gRYdRpF1NtZMXbQHQ4wHNeEN9TNj4ZtRfbBCPePp&amount=1&index=7&proof=FfGJanZUqZrBsri7TnHgZcuqp8bL3MvDwfCaz8oBc2Y4,AbfcJGqX7VYbC9qYpfbqzyyMYJ7aQioVUSF9oP4rYwip,7ofgfSWU2k8LwGfmPiMh8HDHC7nMt7sA3CEMbQqzdyYp,FavYcYDCiASauMGa3i6Bw5wXCygkEdkLk9w3fUD2JY8Z,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "cztu6MLaNRhjPDf6eG2rVvQUNHmA8ASEGmsnArAXrrC",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=cztu6MLaNRhjPDf6eG2rVvQUNHmA8ASEGmsnArAXrrC&amount=1&index=8&proof=A6GYX91MGiJ8ZXGXBmkeybinWCy558F9FgSx3uQUCiSR,HB1Pk7SD5LCyNN9VFhLk7aRAAtpcNBS2iKQ9GkDjVFR7,DURp3cnVAsgnMPxSvxBHCPrcJecGq3DXYY2hKB9EBf51,2fkUoQfEWfRp6ZV3XbTf1p8cSAgo9ikrDxLqBFC5uzHo,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "FUhJApt4RKsVRLFD2sxka52ns1jZGuCZ8RE2eTAtKKms",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=FUhJApt4RKsVRLFD2sxka52ns1jZGuCZ8RE2eTAtKKms&amount=1&index=9&proof=FmtEVM1MjeyNhy58HyT1CFNi4TYZ6qncQ97oE8BZvhL,HB1Pk7SD5LCyNN9VFhLk7aRAAtpcNBS2iKQ9GkDjVFR7,DURp3cnVAsgnMPxSvxBHCPrcJecGq3DXYY2hKB9EBf51,2fkUoQfEWfRp6ZV3XbTf1p8cSAgo9ikrDxLqBFC5uzHo,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "95Me7nkN2GidMRXEPmxCyCqDorGfs9UZ2CUqP4UNDuup",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=95Me7nkN2GidMRXEPmxCyCqDorGfs9UZ2CUqP4UNDuup&amount=1&index=10&proof=CjF5Z7GEZt71RKSWZZ9PFGfCiU78oPbXYFRoC7FrcMNM,HtuPcxHPHygySytcnGzPHqK1xHsnzS3FNuPd9ZMWpN7b,DURp3cnVAsgnMPxSvxBHCPrcJecGq3DXYY2hKB9EBf51,2fkUoQfEWfRp6ZV3XbTf1p8cSAgo9ikrDxLqBFC5uzHo,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "gCBxzS9TPWP6P2tEXvJqb7TCf5vsEsEZpUegXHyHPp2",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=gCBxzS9TPWP6P2tEXvJqb7TCf5vsEsEZpUegXHyHPp2&amount=1&index=11&proof=BZ1X9g6u1rFa6mKnXjX4tVfdeW6EA3BwEZZMnxgHefna,HtuPcxHPHygySytcnGzPHqK1xHsnzS3FNuPd9ZMWpN7b,DURp3cnVAsgnMPxSvxBHCPrcJecGq3DXYY2hKB9EBf51,2fkUoQfEWfRp6ZV3XbTf1p8cSAgo9ikrDxLqBFC5uzHo,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "5Do46jkdtXjD1LeoHFqcaEQALoeRtZSsx6qAy7EV2zY4",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=5Do46jkdtXjD1LeoHFqcaEQALoeRtZSsx6qAy7EV2zY4&amount=1&index=12&proof=5wHmo4AvzS6s5avMNBCxEPFoSj1HCgPhpNadGKqq4A6r,5rLcCaTM99uTjperuJHg1x6xHxnPpsSZmR17q66bMZCb,DKRYscknfdgyYcZpXc3fzDAGnVr1wRumw4XZbN2vveZK,2fkUoQfEWfRp6ZV3XbTf1p8cSAgo9ikrDxLqBFC5uzHo,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "3qJQGNb2Z7qhQRXuykqYViArryJg2jFhoAb96azjy37a",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=3qJQGNb2Z7qhQRXuykqYViArryJg2jFhoAb96azjy37a&amount=1&index=13&proof=Ehwi95SsYBjzUToHCfd4K7RZGvkRM8Ep6pHYdQXQEa8k,5rLcCaTM99uTjperuJHg1x6xHxnPpsSZmR17q66bMZCb,DKRYscknfdgyYcZpXc3fzDAGnVr1wRumw4XZbN2vveZK,2fkUoQfEWfRp6ZV3XbTf1p8cSAgo9ikrDxLqBFC5uzHo,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8a1U3tmWkfV1HwuB4RGjwqKJo14NLn625rWSxK5j3g4s",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8a1U3tmWkfV1HwuB4RGjwqKJo14NLn625rWSxK5j3g4s&amount=1&index=14&proof=7J6oJ8QoJezBXbqUv2pn5WQs3aBiwzNgNSSc8pMDtP4w,4XR3KSsMZWPNXqvD7cBYn9QgwzUaFqTpZ81gF9yH1RT7,DKRYscknfdgyYcZpXc3fzDAGnVr1wRumw4XZbN2vveZK,2fkUoQfEWfRp6ZV3XbTf1p8cSAgo9ikrDxLqBFC5uzHo,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "GqJzw8U5PJ5aQ1rHPkfuYpgDAJR8JaQ7v8j2ehtn6ioQ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=GqJzw8U5PJ5aQ1rHPkfuYpgDAJR8JaQ7v8j2ehtn6ioQ&amount=1&index=15&proof=9gx1fgHdwJyTRvJ96sUHTDho5U4314tDE316hCVoYP5F,4XR3KSsMZWPNXqvD7cBYn9QgwzUaFqTpZ81gF9yH1RT7,DKRYscknfdgyYcZpXc3fzDAGnVr1wRumw4XZbN2vveZK,2fkUoQfEWfRp6ZV3XbTf1p8cSAgo9ikrDxLqBFC5uzHo,ATFWo88XrEZVpmRNVFXNnEJX3Z4F5QNAj8dU7uYZpypN,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "DczcVBycxwDfV86Mez8TKUykKmh2syLNbTG2Tyq7UMzQ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=DczcVBycxwDfV86Mez8TKUykKmh2syLNbTG2Tyq7UMzQ&amount=1&index=16&proof=8tGzSDEc1ngYjwbfTEjWbhbPErwTcc2ZmoyXEAqKpdCa,3unXttZrXkxUDBhmGayZkYKkciepEbUyjYC6yYUthYbR,6Uu7yDWi2YQzRhcYnbu4RjrigTym9iwVFTV7mWRf3A8g,9sQomNXkGPk7AsPPTspvDn9wowmJ51EMrp64MXzgRWXA,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "59cTH3sbV549XNKJgxziWCJnNi7rBK3v1JE9nv36zp5W",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=59cTH3sbV549XNKJgxziWCJnNi7rBK3v1JE9nv36zp5W&amount=1&index=17&proof=EDHRzqJRFqp4KoP9XWEeGiLxpZjntSrMNAH23YbWzFwX,3unXttZrXkxUDBhmGayZkYKkciepEbUyjYC6yYUthYbR,6Uu7yDWi2YQzRhcYnbu4RjrigTym9iwVFTV7mWRf3A8g,9sQomNXkGPk7AsPPTspvDn9wowmJ51EMrp64MXzgRWXA,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "EXXNuCEceXmjASzyJiKkyWAPM4T1rmf1wq6sRR26zBqS",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=EXXNuCEceXmjASzyJiKkyWAPM4T1rmf1wq6sRR26zBqS&amount=1&index=18&proof=E1aesQPmJu1dgLRTpR9isYJZvwRnpLVrukt3Kg5Eq6rL,BsW5dDZHbKJtCN74agXCqy5NrT8tGUXhk7rt9a5ebpyU,6Uu7yDWi2YQzRhcYnbu4RjrigTym9iwVFTV7mWRf3A8g,9sQomNXkGPk7AsPPTspvDn9wowmJ51EMrp64MXzgRWXA,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8g1YLCtbQ5GWCV6ekQiRYSVwMVeSmokGekBn6CDwVgxN",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8g1YLCtbQ5GWCV6ekQiRYSVwMVeSmokGekBn6CDwVgxN&amount=1&index=19&proof=Ewq6wnFWm9JZ1ksQijpQj8iTLQmqnMWbnGSL7GUfpFng,BsW5dDZHbKJtCN74agXCqy5NrT8tGUXhk7rt9a5ebpyU,6Uu7yDWi2YQzRhcYnbu4RjrigTym9iwVFTV7mWRf3A8g,9sQomNXkGPk7AsPPTspvDn9wowmJ51EMrp64MXzgRWXA,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "Ct3FukaKS2K2AutD2N8CPAus3Ja5JssbFuWpzfGj7Y9R",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=Ct3FukaKS2K2AutD2N8CPAus3Ja5JssbFuWpzfGj7Y9R&amount=1&index=20&proof=4YCjq6SorgrQGe2qJxPveC66thYkmtYj49MjSnR1VAh3,Fph93Lvf1ZU9pFaE4yLuJxezy8jaXVWBW8H44FYY4EJC,5agoqGPegsEExfHAhoMmLC7VEDnxBWA9z5P2Kb35c8UC,9sQomNXkGPk7AsPPTspvDn9wowmJ51EMrp64MXzgRWXA,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "7SpUz3i7tTzjmDZR8UvcVMP11Jmgeimh67KCYa7DHCx1",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=7SpUz3i7tTzjmDZR8UvcVMP11Jmgeimh67KCYa7DHCx1&amount=1&index=21&proof=8j77fBPWQYi7MDtqFAL7u7R8EtQm5NyiNEDW5FqdYwtp,Fph93Lvf1ZU9pFaE4yLuJxezy8jaXVWBW8H44FYY4EJC,5agoqGPegsEExfHAhoMmLC7VEDnxBWA9z5P2Kb35c8UC,9sQomNXkGPk7AsPPTspvDn9wowmJ51EMrp64MXzgRWXA,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "GxrjqQEq8scMwijdGgwAbZNPhgGwaRYw7sPtYeQUvrmE",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=GxrjqQEq8scMwijdGgwAbZNPhgGwaRYw7sPtYeQUvrmE&amount=1&index=22&proof=FqHg5UUkZvc43WGmcwp8m2bgz5iu4MJcodR83HHSRHxG,6Fmy3kwhm6JoonWifUno2hSwtSVR1qbzUd3kTAGvzmYY,5agoqGPegsEExfHAhoMmLC7VEDnxBWA9z5P2Kb35c8UC,9sQomNXkGPk7AsPPTspvDn9wowmJ51EMrp64MXzgRWXA,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "AtyR18nNbZ15NrngqBLiY4vnrCm9BJwcjveFz95df6N7",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=AtyR18nNbZ15NrngqBLiY4vnrCm9BJwcjveFz95df6N7&amount=1&index=23&proof=GGAqovNrBtQbdbEdCxVNHmN5UUwqRPJdmgArT97t5cGu,6Fmy3kwhm6JoonWifUno2hSwtSVR1qbzUd3kTAGvzmYY,5agoqGPegsEExfHAhoMmLC7VEDnxBWA9z5P2Kb35c8UC,9sQomNXkGPk7AsPPTspvDn9wowmJ51EMrp64MXzgRWXA,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "EMQD5ZP9zhYmjeCSFbmyaJ5im1XgVocR4WukXskNLMAn",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=EMQD5ZP9zhYmjeCSFbmyaJ5im1XgVocR4WukXskNLMAn&amount=1&index=24&proof=AJvarG3yhgUG17xrwcMnWbjbVGiGpEqvx37ZRkVhpSy4,5nEJCw5nEiV1ZsTcPCT1ANrsFqCmmyFdmSyk6QAC4aB8,2iAZ8ySbWg9SmBzGEpwYuk8bsGmgg2ZRQGrPd9HmewiP,7D1cDu61enCoaixtPZ1SNPBaX3hKwPHwwMigxM6wU5Ge,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "5StYMSgKGUGrqXvBMM9gw2bdekcHUZJTdBstqgGLa6Bq",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=5StYMSgKGUGrqXvBMM9gw2bdekcHUZJTdBstqgGLa6Bq&amount=1&index=25&proof=8PvjBZQpidM2v6QmtU8oZFSSrU5ythvYNh1So2MmE3yN,5nEJCw5nEiV1ZsTcPCT1ANrsFqCmmyFdmSyk6QAC4aB8,2iAZ8ySbWg9SmBzGEpwYuk8bsGmgg2ZRQGrPd9HmewiP,7D1cDu61enCoaixtPZ1SNPBaX3hKwPHwwMigxM6wU5Ge,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "H2YeaJDZ1kK8YcZAFKHMqtYhYTRk5nB4K86WTALFBapc",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=H2YeaJDZ1kK8YcZAFKHMqtYhYTRk5nB4K86WTALFBapc&amount=1&index=26&proof=3bi7WEiw42SwzKRGoGGENboARcgtPPb7mh2DNC89iirh,EBZMeKoc4FcK3EkT9GR4iT1UvuoW8jNreXas6LsU2Y5B,2iAZ8ySbWg9SmBzGEpwYuk8bsGmgg2ZRQGrPd9HmewiP,7D1cDu61enCoaixtPZ1SNPBaX3hKwPHwwMigxM6wU5Ge,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "64p99tZyGfuAijRmPFA14oSbWJgUBx1eggHGGts3Dpoj",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=64p99tZyGfuAijRmPFA14oSbWJgUBx1eggHGGts3Dpoj&amount=1&index=27&proof=5nDHW2K7TSwYesUvtpMRgFeGxB2fMDDvMviqDutSC3r6,EBZMeKoc4FcK3EkT9GR4iT1UvuoW8jNreXas6LsU2Y5B,2iAZ8ySbWg9SmBzGEpwYuk8bsGmgg2ZRQGrPd9HmewiP,7D1cDu61enCoaixtPZ1SNPBaX3hKwPHwwMigxM6wU5Ge,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "AtzCrVeWPS3jxrqq3gn5Q38ZwVLW8XDkaP1PUhhTV3hi",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=AtzCrVeWPS3jxrqq3gn5Q38ZwVLW8XDkaP1PUhhTV3hi&amount=1&index=28&proof=J9R6zB8aBJqu3do3a91XwbHiU9zvQUHctCswWBQAnsTd,4FPMfoSwdksC4T6e6ijoQ61R8StVPzDncEiJAWBfuCD6,4GmgkcEYE28NYSQNALbbetX7jxyPcXQUgWnMcBbpiyVu,7D1cDu61enCoaixtPZ1SNPBaX3hKwPHwwMigxM6wU5Ge,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8ew3omXr2xaZpFpgj9BASiD6okZRWgtsUqUVUm9bw9k",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8ew3omXr2xaZpFpgj9BASiD6okZRWgtsUqUVUm9bw9k&amount=1&index=29&proof=3aVj9XWrgaqSxFcULiYZHhZaepUKtmwGRaF7r2HRs1MD,4FPMfoSwdksC4T6e6ijoQ61R8StVPzDncEiJAWBfuCD6,4GmgkcEYE28NYSQNALbbetX7jxyPcXQUgWnMcBbpiyVu,7D1cDu61enCoaixtPZ1SNPBaX3hKwPHwwMigxM6wU5Ge,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "DMnsLeGcE92PuFtg2AAdfekCzaVbSfUbh7xJF5pqBdzp",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=DMnsLeGcE92PuFtg2AAdfekCzaVbSfUbh7xJF5pqBdzp&amount=1&index=30&proof=J4BLEPLL95VeXnKVBAQ2mWFubAzMRJ33amZDS3ASpEN6,5jLTN2iCcYGitAqo2RD9qBH7Q53NHLbjqx36GeH73Vvb,4GmgkcEYE28NYSQNALbbetX7jxyPcXQUgWnMcBbpiyVu,7D1cDu61enCoaixtPZ1SNPBaX3hKwPHwwMigxM6wU5Ge,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "HviS2cW5FTxa7w1dn41xxX7aQfwR8F8PfNawg9nU28KR",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=HviS2cW5FTxa7w1dn41xxX7aQfwR8F8PfNawg9nU28KR&amount=1&index=31&proof=6fJUuA1sx3YFPcdxeXurCcbcjLhtg31bEQ2M3Zb8m2Fc,5jLTN2iCcYGitAqo2RD9qBH7Q53NHLbjqx36GeH73Vvb,4GmgkcEYE28NYSQNALbbetX7jxyPcXQUgWnMcBbpiyVu,7D1cDu61enCoaixtPZ1SNPBaX3hKwPHwwMigxM6wU5Ge,4N3KwHsRjQJghXwaGe9q85Pm1qrAgBGycH2rsLga8AB3,9Qdcu21eJvb92rw3nboP3LaaugpeMouDDw2FntM12cQK,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "HXmWaE5jQXncyRaSQZDGSA55McXgvnFeLEKnLUkXCMHG",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=HXmWaE5jQXncyRaSQZDGSA55McXgvnFeLEKnLUkXCMHG&amount=1&index=32&proof=2EfJx7yBjyA35msee7bpLZN7WdeBV9xupoQHcS5BrxYA,8xSd6ymCnXRbVkWUaSnPsYEth6Db5hyPWYeQsk9GnsQp,4ypfKjeBvNycrTypQuGy9zExEfn5pd7smhJMSxvL13K6,3tR6SBG3AfYkd9PsUP3gN4aQpK2qx68pRXn33KiDv2Ju,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "4PGNoWLPgqJyBCirTrYoNXkTn8ULAWMt9eeqFPq1tKbD",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=4PGNoWLPgqJyBCirTrYoNXkTn8ULAWMt9eeqFPq1tKbD&amount=1&index=33&proof=5hQU3MPBvqn2EEYkuT7H52RJYhTWS4hpXWH8V6CrNvx3,8xSd6ymCnXRbVkWUaSnPsYEth6Db5hyPWYeQsk9GnsQp,4ypfKjeBvNycrTypQuGy9zExEfn5pd7smhJMSxvL13K6,3tR6SBG3AfYkd9PsUP3gN4aQpK2qx68pRXn33KiDv2Ju,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "Q7Z1QvE5ZpxDwMsR3T1tsG8JCsRiUWrM8N9yd24zuxc",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=Q7Z1QvE5ZpxDwMsR3T1tsG8JCsRiUWrM8N9yd24zuxc&amount=1&index=34&proof=ePT2rPAHai9EFUouBhkiAMmTcNv4zZuxokTHsJkzZ5r,FjJjuSuj5eCVP4D4Jobyeoju9dwgkjbDoPCMCE84tmVM,4ypfKjeBvNycrTypQuGy9zExEfn5pd7smhJMSxvL13K6,3tR6SBG3AfYkd9PsUP3gN4aQpK2qx68pRXn33KiDv2Ju,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "knAaHMBtKzZiAXJSCiAMg5cBShBXJZhML2CmRG9nvCZ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=knAaHMBtKzZiAXJSCiAMg5cBShBXJZhML2CmRG9nvCZ&amount=1&index=35&proof=3yGi2Km87cx75iuwg3PUuUHVUbFniM8W8pjAPZMofW59,FjJjuSuj5eCVP4D4Jobyeoju9dwgkjbDoPCMCE84tmVM,4ypfKjeBvNycrTypQuGy9zExEfn5pd7smhJMSxvL13K6,3tR6SBG3AfYkd9PsUP3gN4aQpK2qx68pRXn33KiDv2Ju,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "CHKfDrEMS3F2wChWyyBUvQaCXnZwP9jU6eqBHUURNW67",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=CHKfDrEMS3F2wChWyyBUvQaCXnZwP9jU6eqBHUURNW67&amount=1&index=36&proof=6MjzStdAgU4zhWiv5UtbpVuLUcKNCvW1JRd4mB1MHqyT,67ndpay8nfVMqi2YQ6s9svZz9kYiA4dk99zRBcHQ4DMr,9oSzHhyBTePAe5U7SotXP26t8h3preW7hswYMP4CnErs,3tR6SBG3AfYkd9PsUP3gN4aQpK2qx68pRXn33KiDv2Ju,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "A13HYY6MBbnDbQtSBEUiYSvV2AsZpazA5eU4KPMGDrzH",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=A13HYY6MBbnDbQtSBEUiYSvV2AsZpazA5eU4KPMGDrzH&amount=1&index=37&proof=EQ2qKS7cKeCV6yivg5e3xduf9vhoQqp1Tc4RBcxCVMJg,67ndpay8nfVMqi2YQ6s9svZz9kYiA4dk99zRBcHQ4DMr,9oSzHhyBTePAe5U7SotXP26t8h3preW7hswYMP4CnErs,3tR6SBG3AfYkd9PsUP3gN4aQpK2qx68pRXn33KiDv2Ju,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "2rhosKgHdNpXgu4sZNakeLrqkr7WTNQUpJaXGwmDA6Vg",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=2rhosKgHdNpXgu4sZNakeLrqkr7WTNQUpJaXGwmDA6Vg&amount=1&index=38&proof=CWEFhUDj8SGZFzJEjR6gQq2wPxcLCFQRV6FJcGGmUdh7,3ZirzYePG2vTVzDFZLuDiCGULoSissT9shLCp76UFWZ1,9oSzHhyBTePAe5U7SotXP26t8h3preW7hswYMP4CnErs,3tR6SBG3AfYkd9PsUP3gN4aQpK2qx68pRXn33KiDv2Ju,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8SvZVk9VttgeqjfrsDxxQRYf3Jm9nhJLzkm8F8mJZeoo",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8SvZVk9VttgeqjfrsDxxQRYf3Jm9nhJLzkm8F8mJZeoo&amount=1&index=39&proof=6wn58wnaK5kPQCAnqWQb4wrkDd68QoqFpJVKczTbQjQp,3ZirzYePG2vTVzDFZLuDiCGULoSissT9shLCp76UFWZ1,9oSzHhyBTePAe5U7SotXP26t8h3preW7hswYMP4CnErs,3tR6SBG3AfYkd9PsUP3gN4aQpK2qx68pRXn33KiDv2Ju,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "BYc8fh1rDCsmDdndRXSgYKXS3r55RA2em3SYrFaWJe9a",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=BYc8fh1rDCsmDdndRXSgYKXS3r55RA2em3SYrFaWJe9a&amount=1&index=40&proof=CYSpoUBxBihGi8NS97H2gRbo1aeHHHwmBFCHWAv1TTnW,7DAM16X81BPFb3Q5qYxf2AzmZtgzMZkWqsvMFrRpD7qs,52iAPFyEFt8eMkUGEtAmLz8UUWW8afMaAsHBJG6TBNDQ,5Suwrs7KERSCXo69kJc9VoS2Y28ceZvSyvzxCqzZiUWH,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "3w2kTC7SuGxhViRUEdYsyity8oTfzgnxWtzbZRh8T7ot",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=3w2kTC7SuGxhViRUEdYsyity8oTfzgnxWtzbZRh8T7ot&amount=1&index=41&proof=J4Ec2afGsoS1BPUvdGUMCJ3EufwEmyPB1uB52kkKRLin,7DAM16X81BPFb3Q5qYxf2AzmZtgzMZkWqsvMFrRpD7qs,52iAPFyEFt8eMkUGEtAmLz8UUWW8afMaAsHBJG6TBNDQ,5Suwrs7KERSCXo69kJc9VoS2Y28ceZvSyvzxCqzZiUWH,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "6wQt3YzpcAGbKrgLgmUVPR74g4MYJbtwBFt13rW21oTi",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=6wQt3YzpcAGbKrgLgmUVPR74g4MYJbtwBFt13rW21oTi&amount=1&index=42&proof=3sbnZ75dbhGkNYk7gJCQCeEurtk2UCUp2qaAn1sgAg2P,546ZsgSFHxnjkqcsCu9pfVUeXa4CmrrZDDfZgXBjugWU,52iAPFyEFt8eMkUGEtAmLz8UUWW8afMaAsHBJG6TBNDQ,5Suwrs7KERSCXo69kJc9VoS2Y28ceZvSyvzxCqzZiUWH,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "6k7r29wUKw2abSgbFCLJkZc6fd6xF9LikorBQ1rb9ZZJ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=6k7r29wUKw2abSgbFCLJkZc6fd6xF9LikorBQ1rb9ZZJ&amount=1&index=43&proof=3h4HXPCoPuoLCnoXhnW2ypdDqyoXTcoPdZVaSsmCdJta,546ZsgSFHxnjkqcsCu9pfVUeXa4CmrrZDDfZgXBjugWU,52iAPFyEFt8eMkUGEtAmLz8UUWW8afMaAsHBJG6TBNDQ,5Suwrs7KERSCXo69kJc9VoS2Y28ceZvSyvzxCqzZiUWH,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8nYKRsSVqE8WoPJBxheQjWux2bjYXqZm1v5aXiRJNRjo",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8nYKRsSVqE8WoPJBxheQjWux2bjYXqZm1v5aXiRJNRjo&amount=1&index=44&proof=BSUrnEX1PG2KcG58R2NrXQip8eroACExU7HL8co7TKTi,CatJGeYUHuaupenuNqXGMKPJutNg4mqk5goCc3B6KdKe,J7KAQhLNucJk6TP1FQvncpsYB7SXos8KU3fCqAyQ4zvz,5Suwrs7KERSCXo69kJc9VoS2Y28ceZvSyvzxCqzZiUWH,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "E7oFfHbp9UfP8jYaK6NiaBZCUhs95igJKfphsYtd3JTH",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=E7oFfHbp9UfP8jYaK6NiaBZCUhs95igJKfphsYtd3JTH&amount=1&index=45&proof=67exNhK8xs18XWC2cBanY2TvWpAgDxQMuryv23X2jAc4,CatJGeYUHuaupenuNqXGMKPJutNg4mqk5goCc3B6KdKe,J7KAQhLNucJk6TP1FQvncpsYB7SXos8KU3fCqAyQ4zvz,5Suwrs7KERSCXo69kJc9VoS2Y28ceZvSyvzxCqzZiUWH,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "AowwR532xpgjPEMKtaxxv4aFxi9dipkV5wxkj65HugPG",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=AowwR532xpgjPEMKtaxxv4aFxi9dipkV5wxkj65HugPG&amount=1&index=46&proof=6u5GSNzTYHzd76brEVXqufDZs1ShkZdrdyVGSRwK1Cvk,24mHXCxkxcSd5ce83PdrWffSJQav9nJV5aL88H6LZRa3,J7KAQhLNucJk6TP1FQvncpsYB7SXos8KU3fCqAyQ4zvz,5Suwrs7KERSCXo69kJc9VoS2Y28ceZvSyvzxCqzZiUWH,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "3fA3AcCravJQRmRz3d9wrhJDY8KSfVpKapCDedHc8DuC",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=3fA3AcCravJQRmRz3d9wrhJDY8KSfVpKapCDedHc8DuC&amount=1&index=47&proof=2fbPVkpMRcpCWkka6wjXJLqwTu8x4cqU73iJvDRXsqQt,24mHXCxkxcSd5ce83PdrWffSJQav9nJV5aL88H6LZRa3,J7KAQhLNucJk6TP1FQvncpsYB7SXos8KU3fCqAyQ4zvz,5Suwrs7KERSCXo69kJc9VoS2Y28ceZvSyvzxCqzZiUWH,6yTXQec5Y9253FEvxqW5sTK7aJ76yCem5Vzb1NHGpdC3,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "9JermBNexnJP3RykvuxoaUt4Xs7Ta4xTLoyMtXiSQH4W",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=9JermBNexnJP3RykvuxoaUt4Xs7Ta4xTLoyMtXiSQH4W&amount=1&index=48&proof=ES1xnUjZXwJXtQVqUZYuftzuWbpKgLEi8ZUx5Jhjd87H,GFiddwXXZFNrzxNgqttxkEqRX3uwc68HFt9q5ALin5NG,C9vPHE41LnanbhDSB7v36jCLjSvAKsj4fDtZ1gWYnmGL,2f3U5CF8oH5EE7ymctREo7j2EyNANFkX5Px531aGQ9aJ,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "5MzJJqTtXZpBjDBuyJTLzCwN9NzWhZW2Wc28oLmh2Nmb",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=5MzJJqTtXZpBjDBuyJTLzCwN9NzWhZW2Wc28oLmh2Nmb&amount=1&index=49&proof=DZhtF8xVMPBKyeo7gEMYExbhzRT9gLWJ2fKS9bigDPns,GFiddwXXZFNrzxNgqttxkEqRX3uwc68HFt9q5ALin5NG,C9vPHE41LnanbhDSB7v36jCLjSvAKsj4fDtZ1gWYnmGL,2f3U5CF8oH5EE7ymctREo7j2EyNANFkX5Px531aGQ9aJ,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "54X8A5YJy6j5xBEHGJB9XpVqBNPUTwVCNqxRcbD6XNjb",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=54X8A5YJy6j5xBEHGJB9XpVqBNPUTwVCNqxRcbD6XNjb&amount=1&index=50&proof=6ixJZJmB38GSHK29JmKBkr1MLvJESabZx8UYxmAcdczy,F35dzqRD2qkUj2Wh89XPuyxhwKtZWprWL7ysTYcR1UJ1,C9vPHE41LnanbhDSB7v36jCLjSvAKsj4fDtZ1gWYnmGL,2f3U5CF8oH5EE7ymctREo7j2EyNANFkX5Px531aGQ9aJ,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "rrYjD8GdsaCTw2Rp5qea3H6XgzcxNtjBPqWyQHKxMHr",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=rrYjD8GdsaCTw2Rp5qea3H6XgzcxNtjBPqWyQHKxMHr&amount=1&index=51&proof=E1dhdMUvsWZPs7ojwEKgMbawabu1oFAKopYKcALNszRm,F35dzqRD2qkUj2Wh89XPuyxhwKtZWprWL7ysTYcR1UJ1,C9vPHE41LnanbhDSB7v36jCLjSvAKsj4fDtZ1gWYnmGL,2f3U5CF8oH5EE7ymctREo7j2EyNANFkX5Px531aGQ9aJ,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "HJQWZKPaE34EEELwJKQkeQhgsHSjH1Yq51mBdhFJBTy5",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=HJQWZKPaE34EEELwJKQkeQhgsHSjH1Yq51mBdhFJBTy5&amount=1&index=52&proof=CSkNzPB5fgLToYU9jFu5qTT3DBc5JhDRddi99PxLQm4V,B78UpDoZireQokY4mA2epQaeKXFxypquh3qXYs72B8Sv,C3RFXB6wj77j8vbUcpQaJ1mCLJcqJzLFM63PGkk9y5CA,2f3U5CF8oH5EE7ymctREo7j2EyNANFkX5Px531aGQ9aJ,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "9P3gUJNn84Y5EDVTeMY4pNF3YxgTKZusHPQWvtooFbk9",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=9P3gUJNn84Y5EDVTeMY4pNF3YxgTKZusHPQWvtooFbk9&amount=1&index=53&proof=8YK2q66y93pKLxnruFt34CpY3rqPkL7cHrEVZPxH81gw,B78UpDoZireQokY4mA2epQaeKXFxypquh3qXYs72B8Sv,C3RFXB6wj77j8vbUcpQaJ1mCLJcqJzLFM63PGkk9y5CA,2f3U5CF8oH5EE7ymctREo7j2EyNANFkX5Px531aGQ9aJ,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "4PM9naKsVLBxMf9SzUtpx4VwA3unzF1UaRfmQ5oW9jq7",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=4PM9naKsVLBxMf9SzUtpx4VwA3unzF1UaRfmQ5oW9jq7&amount=1&index=54&proof=F2BAKu1uA5xzPrbjHQJUiNtG9HWowj1Ej3BkCeUw7pq8,49cvsU6a4YbMe67YFCZ3Mf98ZnBk1NEbSGMxJ1bmnH7W,C3RFXB6wj77j8vbUcpQaJ1mCLJcqJzLFM63PGkk9y5CA,2f3U5CF8oH5EE7ymctREo7j2EyNANFkX5Px531aGQ9aJ,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "BMavUmXwEryF82NSzLC5Xp3eCtDcU6iLC1WzeMiEuiSF",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=BMavUmXwEryF82NSzLC5Xp3eCtDcU6iLC1WzeMiEuiSF&amount=1&index=55&proof=4BcMZpocTaAs3hDgTEPiwXkKenH25YJ4wUMpgCaMSoSB,49cvsU6a4YbMe67YFCZ3Mf98ZnBk1NEbSGMxJ1bmnH7W,C3RFXB6wj77j8vbUcpQaJ1mCLJcqJzLFM63PGkk9y5CA,2f3U5CF8oH5EE7ymctREo7j2EyNANFkX5Px531aGQ9aJ,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "7KFUiJNkLx3ZLzfZXmYCAoQqdZqyDsWm7vc9YNet89G7",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=7KFUiJNkLx3ZLzfZXmYCAoQqdZqyDsWm7vc9YNet89G7&amount=1&index=56&proof=3sYS5HYSM1JbbfnwzXVNvyhwBEeqeeUvs5sBtM9onWdj,AFJSK44TYWbSas4RpPsFSsPU5YofqKb7N27DfYTJXVX,2KN7y44bDEeiEf5gacjJP9wpmoa74914dAWa2togZwPn,ZejCk1HNc8UjgajikC3bGRKGSHHbviwKi72fvK3jmp2,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "Hc6Gz25Y2aN27xt2McZmdKihRtw55nYUYznmqDooFMUb",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=Hc6Gz25Y2aN27xt2McZmdKihRtw55nYUYznmqDooFMUb&amount=1&index=57&proof=w8vnWFv2VXgWEZuvo2bBuzmePJn9j3SUkVRerSFhsjY,AFJSK44TYWbSas4RpPsFSsPU5YofqKb7N27DfYTJXVX,2KN7y44bDEeiEf5gacjJP9wpmoa74914dAWa2togZwPn,ZejCk1HNc8UjgajikC3bGRKGSHHbviwKi72fvK3jmp2,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8w4kUf5TC9X5kZWg6Ret5tYZXeYEf6a2VzqcbKJCRxLG",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8w4kUf5TC9X5kZWg6Ret5tYZXeYEf6a2VzqcbKJCRxLG&amount=1&index=58&proof=FdBWsSJuHuu5vmE8V3MRDxa5HiuyoTKemimVNjSKS4Y9,CbYAfHEVCUk1qqyVYLupZwbMMxSYnd9vu1DTzvHqrwXG,2KN7y44bDEeiEf5gacjJP9wpmoa74914dAWa2togZwPn,ZejCk1HNc8UjgajikC3bGRKGSHHbviwKi72fvK3jmp2,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "AjHNrtRg1nPDVYKs453SpXoFEFWtSAw2cRPN9B5KHMjn",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=AjHNrtRg1nPDVYKs453SpXoFEFWtSAw2cRPN9B5KHMjn&amount=1&index=59&proof=5EC6eTPyMRSDiNtbrUkNFoX19QfctRBoPPKVMCVN2Dy3,CbYAfHEVCUk1qqyVYLupZwbMMxSYnd9vu1DTzvHqrwXG,2KN7y44bDEeiEf5gacjJP9wpmoa74914dAWa2togZwPn,ZejCk1HNc8UjgajikC3bGRKGSHHbviwKi72fvK3jmp2,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "97QLmVG9hS79YK9X17izuV49Ps1FuKtFM3QCNJ4TT8F6",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=97QLmVG9hS79YK9X17izuV49Ps1FuKtFM3QCNJ4TT8F6&amount=1&index=60&proof=Bv65ipALa6vTS5BpcjP9tJLYvksWQf49WwQVjSLcSJkJ,Coa2iTUH7SZSzxZ67ftWu7jJmBm9Wx2i9pfDTAbNhDwE,2cLwC1vhR4AJD1Sqs7KjbmC88fT4Wmt6rnd5CHaERwEy,ZejCk1HNc8UjgajikC3bGRKGSHHbviwKi72fvK3jmp2,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "Ge4rc42ik3BiF2YyRjEdftxdGCdMy1SCzwwGcGmA95Mb",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=Ge4rc42ik3BiF2YyRjEdftxdGCdMy1SCzwwGcGmA95Mb&amount=1&index=61&proof=EJYqrYWPZiGi8jVVdwjtfBLJ37QURWdQU7t86Gv9Kpn8,Coa2iTUH7SZSzxZ67ftWu7jJmBm9Wx2i9pfDTAbNhDwE,2cLwC1vhR4AJD1Sqs7KjbmC88fT4Wmt6rnd5CHaERwEy,ZejCk1HNc8UjgajikC3bGRKGSHHbviwKi72fvK3jmp2,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "F2K897RZatybN4iqChVEeWxCxPy2YEQw2qkYQ8zqTgXo",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=F2K897RZatybN4iqChVEeWxCxPy2YEQw2qkYQ8zqTgXo&amount=1&index=62&proof=Ffjg6k3Njepd8E1cEuh39zKVzKJ7fNrh5xEHLyDqAerD,EgwpEaFfYmKdiihWfv7m8q1otqmnxUonEnf4K836aFZ7,2cLwC1vhR4AJD1Sqs7KjbmC88fT4Wmt6rnd5CHaERwEy,ZejCk1HNc8UjgajikC3bGRKGSHHbviwKi72fvK3jmp2,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "HT17vzEEEk2nTjaSs1EtecgcfjkshvQsEx5ZxB6iEwuc",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=HT17vzEEEk2nTjaSs1EtecgcfjkshvQsEx5ZxB6iEwuc&amount=1&index=63&proof=3B3Wbt842d4dED3Sv9PZjzKZx2i3iJ2GKEde3TWFCgre,EgwpEaFfYmKdiihWfv7m8q1otqmnxUonEnf4K836aFZ7,2cLwC1vhR4AJD1Sqs7KjbmC88fT4Wmt6rnd5CHaERwEy,ZejCk1HNc8UjgajikC3bGRKGSHHbviwKi72fvK3jmp2,CKGTdKuyvH2duqxJT7C2WsYCQdg8pHptVbRnLnbu5Waj,AKU52LFvXoANpsWrkqmSipKkghgQHZ5k3zC8mKPCAbKs,99MaEG7NfhXYbMskCix6oo3rTHX5PCB2ZfDSMaGgJiSU,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "4GJ9DY46AGA6fVBq9VrKxouG3Ye3Hbp6KyoBBWTAcwxV",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=4GJ9DY46AGA6fVBq9VrKxouG3Ye3Hbp6KyoBBWTAcwxV&amount=1&index=64&proof=9osMoSZ5yBzKK47zwKcR4g51ggCeHrtEhLzye4YKT3or,D3FDLnZZh5HVMGqQJsUKXEgBToGtKnxcUWGdYmAXx7u5,4bH5k7z5VhWW1Zkfobw9FEzMNBrQoNbBYwDSntNywWAk,BtjGn2PyaacGzBPJbRitSKDuhwWjUnfDMHiibesH4bW2,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "HuxHP49BckPrK5jQuVmRJrHtyGfJjTN5fYC5B1teSgRh",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=HuxHP49BckPrK5jQuVmRJrHtyGfJjTN5fYC5B1teSgRh&amount=1&index=65&proof=9Ncie2cYwbRSQjn763hw9Q5ZNw6J3EXrQLQTJ5B3LWpT,D3FDLnZZh5HVMGqQJsUKXEgBToGtKnxcUWGdYmAXx7u5,4bH5k7z5VhWW1Zkfobw9FEzMNBrQoNbBYwDSntNywWAk,BtjGn2PyaacGzBPJbRitSKDuhwWjUnfDMHiibesH4bW2,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "5EkU3KueRC8TMRfvTEk2WjGUnqogCaezH1xAQTDxQ7Ji",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=5EkU3KueRC8TMRfvTEk2WjGUnqogCaezH1xAQTDxQ7Ji&amount=1&index=66&proof=ChwWVyLPxAuK318M7PFtQqvfqMd4Yro5YmDF3x9u7Eq2,EEZUk9FJLFMCkRVqHtTFtsY1YQaTcXNLTAD5Henawe1a,4bH5k7z5VhWW1Zkfobw9FEzMNBrQoNbBYwDSntNywWAk,BtjGn2PyaacGzBPJbRitSKDuhwWjUnfDMHiibesH4bW2,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "9Ax7PmhB4kV2yBs7qeGE4Ze6ymYN19PyECeUxw8mmcWa",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=9Ax7PmhB4kV2yBs7qeGE4Ze6ymYN19PyECeUxw8mmcWa&amount=1&index=67&proof=EPjJdcQAozDe6kDDfRcEN43GgztukbZ9rmfczzt9SpD,EEZUk9FJLFMCkRVqHtTFtsY1YQaTcXNLTAD5Henawe1a,4bH5k7z5VhWW1Zkfobw9FEzMNBrQoNbBYwDSntNywWAk,BtjGn2PyaacGzBPJbRitSKDuhwWjUnfDMHiibesH4bW2,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "5aiY3KBVHqTuzoVwsfQKtQ96uGkkaP2MLzt4ZpkFpVA4",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=5aiY3KBVHqTuzoVwsfQKtQ96uGkkaP2MLzt4ZpkFpVA4&amount=1&index=68&proof=63WXDKtyGNUqfDKmMJG9nnU5VRFBrJtErMLBo3Fkhcmq,6dLS2jmdHJfxMGqFdHLPUnzCTRwUsK6btgTeQJ35u6sc,9nC5m1czGVWTAqdAJBv4skwJDAHtvQhwE2CjCRauAZT4,BtjGn2PyaacGzBPJbRitSKDuhwWjUnfDMHiibesH4bW2,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8xdsMWuZ8R3erEDJ1oiY1TYBUs9LC4FVgBQDdVKhNUdi",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8xdsMWuZ8R3erEDJ1oiY1TYBUs9LC4FVgBQDdVKhNUdi&amount=1&index=69&proof=FJ3eRA9h9eTP6awJdUeTyLJ626zsJnfARRfgRwQQ5Gkd,6dLS2jmdHJfxMGqFdHLPUnzCTRwUsK6btgTeQJ35u6sc,9nC5m1czGVWTAqdAJBv4skwJDAHtvQhwE2CjCRauAZT4,BtjGn2PyaacGzBPJbRitSKDuhwWjUnfDMHiibesH4bW2,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "66VmrNVA6nzEZKUHx5puKfq52imfiwjUJKcGYA2HUVuj",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=66VmrNVA6nzEZKUHx5puKfq52imfiwjUJKcGYA2HUVuj&amount=1&index=70&proof=7F9nNX6y2SYSGa3R5iUWrKz2YdtdFrKQ31vNzc5EyFyN,24MabCGdE2VLYkDrngAvbwKejWrnShFAKAAFQxSkkiMH,9nC5m1czGVWTAqdAJBv4skwJDAHtvQhwE2CjCRauAZT4,BtjGn2PyaacGzBPJbRitSKDuhwWjUnfDMHiibesH4bW2,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "Ej7CvJBA12id8tpBAw6mgteH5tETNQ3DWExe6DNRFy3B",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=Ej7CvJBA12id8tpBAw6mgteH5tETNQ3DWExe6DNRFy3B&amount=1&index=71&proof=9ptJndtJDn4G8CmzQC8hwzPfFAqEitiAYb1Mf1iCAbcA,24MabCGdE2VLYkDrngAvbwKejWrnShFAKAAFQxSkkiMH,9nC5m1czGVWTAqdAJBv4skwJDAHtvQhwE2CjCRauAZT4,BtjGn2PyaacGzBPJbRitSKDuhwWjUnfDMHiibesH4bW2,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "AogxKZXbkTD78tA1tbneYjFSFVCDNqWVX6Ras6Rad2da",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=AogxKZXbkTD78tA1tbneYjFSFVCDNqWVX6Ras6Rad2da&amount=1&index=72&proof=8VpTjuwZXt6vBvurHcKQLCbBX6yifhnBbigFCApjTVi7,HMJCZhYsPRE9NMT9aFDBar8U9LtPZTj1McTT9Ui3Kdd8,EwSCAQdH7nGzcHoLAe6sgBHm3SVnvEVD82AKvWiVvRfQ,4kFAbZBcnrk5BqYKexW5XQGwyUARQmq7YxXZ2dYaEjpq,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "7qNdC1kkQEj4HCSNv546CWJo2yiCriPcCC8X1guzPQxP",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=7qNdC1kkQEj4HCSNv546CWJo2yiCriPcCC8X1guzPQxP&amount=1&index=73&proof=8YvP4PiPF1BMnQehfVK73hpV4uyZr9NuNz7k9AcGwwMX,HMJCZhYsPRE9NMT9aFDBar8U9LtPZTj1McTT9Ui3Kdd8,EwSCAQdH7nGzcHoLAe6sgBHm3SVnvEVD82AKvWiVvRfQ,4kFAbZBcnrk5BqYKexW5XQGwyUARQmq7YxXZ2dYaEjpq,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "G6VwF4X6HZFFnroyJ5nKPFBQ6eX2YZo1n34KqPp1RBu7",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=G6VwF4X6HZFFnroyJ5nKPFBQ6eX2YZo1n34KqPp1RBu7&amount=1&index=74&proof=HDnaXcX4xPH7Xvzty3dQGRx73gwH4bzPjqML7BuHCDQ1,2C1gQqWpnJGy599NYgdgo9hygHEFYADifQqafHaNnyBB,EwSCAQdH7nGzcHoLAe6sgBHm3SVnvEVD82AKvWiVvRfQ,4kFAbZBcnrk5BqYKexW5XQGwyUARQmq7YxXZ2dYaEjpq,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8NDDD4ciNRiW3S5gxkoT4bRzxq7k1LDSeYDrDsojbKhS",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8NDDD4ciNRiW3S5gxkoT4bRzxq7k1LDSeYDrDsojbKhS&amount=1&index=75&proof=H9WGW27xEPTA2PZVRBodSR8p7GxUx28SLF5xLWA62iZd,2C1gQqWpnJGy599NYgdgo9hygHEFYADifQqafHaNnyBB,EwSCAQdH7nGzcHoLAe6sgBHm3SVnvEVD82AKvWiVvRfQ,4kFAbZBcnrk5BqYKexW5XQGwyUARQmq7YxXZ2dYaEjpq,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "FapMhu1Vk5oXnw7q1ucHmLfnAQQJzwgLUsTEw9Bb5NyR",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=FapMhu1Vk5oXnw7q1ucHmLfnAQQJzwgLUsTEw9Bb5NyR&amount=1&index=76&proof=3PBH4sD8T9opAwVrQawXewKX7GJoGfdyW2VnoEuwJSiT,7GRR9qgC5xS5hZgV3BCW8pchVbHZ57AT9q55sRAsqbuq,EmFRby73YDofC94bvDnjQTuDwADAYoqvFMs6WhWxVkMB,4kFAbZBcnrk5BqYKexW5XQGwyUARQmq7YxXZ2dYaEjpq,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "7PHJ8LPkyECFgD4CNS8qif1acAB5ZPnsdXC9fbpd2u7t",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=7PHJ8LPkyECFgD4CNS8qif1acAB5ZPnsdXC9fbpd2u7t&amount=1&index=77&proof=2gge61UXdFdfmyyx1tt5eU64C8ZXKMBgoyfmWonAjLNA,7GRR9qgC5xS5hZgV3BCW8pchVbHZ57AT9q55sRAsqbuq,EmFRby73YDofC94bvDnjQTuDwADAYoqvFMs6WhWxVkMB,4kFAbZBcnrk5BqYKexW5XQGwyUARQmq7YxXZ2dYaEjpq,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "W4Awchro6i2npUHxD5znZSG56D2uLhHZ9g9JDBtYU2g",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=W4Awchro6i2npUHxD5znZSG56D2uLhHZ9g9JDBtYU2g&amount=1&index=78&proof=HJNBMbd9Fnj9NhtNrxmBhwXTBhZMYtru5uPhZ6c7MnWf,8jn6pfzCzLPkDq3A8mCPYkaXSnkaoFr5wU9vgQDdQqy5,EmFRby73YDofC94bvDnjQTuDwADAYoqvFMs6WhWxVkMB,4kFAbZBcnrk5BqYKexW5XQGwyUARQmq7YxXZ2dYaEjpq,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "7CKFjYqZLi4Bjpi7HHFHhzqNZGEvLf6gKjGc9eWR5p2C",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=7CKFjYqZLi4Bjpi7HHFHhzqNZGEvLf6gKjGc9eWR5p2C&amount=1&index=79&proof=EsxoCrsaVebkcqjf94sSwTMVEniAWhdKGnTDvxAvMT3E,8jn6pfzCzLPkDq3A8mCPYkaXSnkaoFr5wU9vgQDdQqy5,EmFRby73YDofC94bvDnjQTuDwADAYoqvFMs6WhWxVkMB,4kFAbZBcnrk5BqYKexW5XQGwyUARQmq7YxXZ2dYaEjpq,9GeKkKzNax6mTsyNVPR7Hv5bh4BCGYU5H9DurC6ZpxyJ,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "DNu78xXbwCuN7bfsKmYeiA5rJQMPoDa1d9N74zkmwh9e",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=DNu78xXbwCuN7bfsKmYeiA5rJQMPoDa1d9N74zkmwh9e&amount=1&index=80&proof=7yFu2EDMUEuFeMgoZpJEUvAK88ssNtqbFnt2qgMrattW,zyeJa1if3X9dYTUewmum5RqAhN6KVNvKTNSM6xTqrxK,47mdpZM6wUMXBKdfRAdy1BhmA1Tp5yrXwxCVA7FmFetn,5Qw5f92JyUKSH1YLa7spKvbYN48sJWw9H1Ui7JXgdBa2,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "7pHst6847u5QevsnKeVXS5dJeBJqiirAD5mpGPbq9gAD",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=7pHst6847u5QevsnKeVXS5dJeBJqiirAD5mpGPbq9gAD&amount=1&index=81&proof=FosarRptam9vpgBUiRnwrQgu1EjYhdVszsBBP8ACbx7Y,zyeJa1if3X9dYTUewmum5RqAhN6KVNvKTNSM6xTqrxK,47mdpZM6wUMXBKdfRAdy1BhmA1Tp5yrXwxCVA7FmFetn,5Qw5f92JyUKSH1YLa7spKvbYN48sJWw9H1Ui7JXgdBa2,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "3JXUGYU9hcvoLa746ZAmxofuEZkb2sZFVLNKC9ac2bLZ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=3JXUGYU9hcvoLa746ZAmxofuEZkb2sZFVLNKC9ac2bLZ&amount=1&index=82&proof=5FdukJVrUBChDaLywrnFau21QeLrFg9ZRrpGj3vgWREE,6PjWcbeNLUfofzXZELdYF33e2NhDZbjtCFPxKiJZsVvU,47mdpZM6wUMXBKdfRAdy1BhmA1Tp5yrXwxCVA7FmFetn,5Qw5f92JyUKSH1YLa7spKvbYN48sJWw9H1Ui7JXgdBa2,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "DJjtjkcGbqGgbPW5hBDMPDYmWFMBc5CkB5oXnAFHH1oH",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=DJjtjkcGbqGgbPW5hBDMPDYmWFMBc5CkB5oXnAFHH1oH&amount=1&index=83&proof=7mK3HdmYv7MY6XBjaGm22oNStWtEmsqDVPNgxkbPdhSE,6PjWcbeNLUfofzXZELdYF33e2NhDZbjtCFPxKiJZsVvU,47mdpZM6wUMXBKdfRAdy1BhmA1Tp5yrXwxCVA7FmFetn,5Qw5f92JyUKSH1YLa7spKvbYN48sJWw9H1Ui7JXgdBa2,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "AjdEmqkobPyE68or48M11e8r4qHD4ycfL6y91Xu3MHMh",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=AjdEmqkobPyE68or48M11e8r4qHD4ycfL6y91Xu3MHMh&amount=1&index=84&proof=AMwPefFy6ciCQLui8iCAYFdcsXCrT6QcT4VQibqefKmi,4guEXjAhU9cSzBtdBkeFVMh2hVZJfmUPKCxBUtkNcBFY,CWWtZ4q6GNZvcLCHF3qDF5fvnM7hCvZ1ph4NgdMqewm2,5Qw5f92JyUKSH1YLa7spKvbYN48sJWw9H1Ui7JXgdBa2,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "EA4D6Fzs2rQBZewXjEUuR9m1euPsSHVPuTAhPACL3Xdh",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=EA4D6Fzs2rQBZewXjEUuR9m1euPsSHVPuTAhPACL3Xdh&amount=1&index=85&proof=Bfh65T8fYqXYixkvVQjxo3eXvuavs1ULEbLTzEfrkWD3,4guEXjAhU9cSzBtdBkeFVMh2hVZJfmUPKCxBUtkNcBFY,CWWtZ4q6GNZvcLCHF3qDF5fvnM7hCvZ1ph4NgdMqewm2,5Qw5f92JyUKSH1YLa7spKvbYN48sJWw9H1Ui7JXgdBa2,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "Ej7CvJBA12id8tpBAw6mgteH5tETNQ3DWExe6DNRFy3B",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=Ej7CvJBA12id8tpBAw6mgteH5tETNQ3DWExe6DNRFy3B&amount=1&index=86&proof=A56gs679bC5h57nCnPUW9xLwBpNExLrrXD29mR9PHwGm,DVxofjxgME52D5rABzB9mNFYsyqcEKuzaosF5fx5eoqH,CWWtZ4q6GNZvcLCHF3qDF5fvnM7hCvZ1ph4NgdMqewm2,5Qw5f92JyUKSH1YLa7spKvbYN48sJWw9H1Ui7JXgdBa2,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "BPm5W6SGDiDj4XCC5y1BdCKqCqum93oUb5fVxZbbiuiD",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=BPm5W6SGDiDj4XCC5y1BdCKqCqum93oUb5fVxZbbiuiD&amount=1&index=87&proof=2EvHHsakibhV1CfmxpkQiT9wMFpmdA2z6c5UaM73N5hz,DVxofjxgME52D5rABzB9mNFYsyqcEKuzaosF5fx5eoqH,CWWtZ4q6GNZvcLCHF3qDF5fvnM7hCvZ1ph4NgdMqewm2,5Qw5f92JyUKSH1YLa7spKvbYN48sJWw9H1Ui7JXgdBa2,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "9NQuEvu7Szb8JnsvWZmkKYjuzhcrKr7EtdLBP7FbPvj3",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=9NQuEvu7Szb8JnsvWZmkKYjuzhcrKr7EtdLBP7FbPvj3&amount=1&index=88&proof=738bsRDrL2ipzUcA5UhX48Wixk1wzjxGCvM83XJhYkWp,5TjxXCX1X1kdEfPEsSk8z7xbQtCa6gcCQbTjB6mrToRe,7KpJx7rMPLMVXDsy8iDPRJNgmWBrgcoRMTFDeB9G6Yh2,8VFW82jn3qDhK2MuAZ8Pf3ZMmZ93a7JHCnLdGiGVftDX,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "ABiKq6AaQuYY3PLUJNiXwFCVZ8t1YsoGtxJykg3szwpd",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=ABiKq6AaQuYY3PLUJNiXwFCVZ8t1YsoGtxJykg3szwpd&amount=1&index=89&proof=998xyBaPfUmDLFiER9YWysx2uuFUSaJBdK6AB95BbEd1,5TjxXCX1X1kdEfPEsSk8z7xbQtCa6gcCQbTjB6mrToRe,7KpJx7rMPLMVXDsy8iDPRJNgmWBrgcoRMTFDeB9G6Yh2,8VFW82jn3qDhK2MuAZ8Pf3ZMmZ93a7JHCnLdGiGVftDX,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "4dTusreSdQJgFU6DG6LeUgpFyGeNrThtxEZsMy7Gurzp",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=4dTusreSdQJgFU6DG6LeUgpFyGeNrThtxEZsMy7Gurzp&amount=1&index=90&proof=ACh3CiWDtyULsU8j3G315SjZYo5uo2zmVh6v4GJ46g5L,9q4Cit4zAyGeDqKNTaVxhh8B6ZyyEExJJY9HsVz7TUd9,7KpJx7rMPLMVXDsy8iDPRJNgmWBrgcoRMTFDeB9G6Yh2,8VFW82jn3qDhK2MuAZ8Pf3ZMmZ93a7JHCnLdGiGVftDX,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "HVkcx3R2uwiPqhf9Dt3j3pPa2cXvieEJweX4BVihbGsq",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=HVkcx3R2uwiPqhf9Dt3j3pPa2cXvieEJweX4BVihbGsq&amount=1&index=91&proof=ExLQBBbfKxQgoEF4usnhxgHJhQwbRmEW5biCxcTP7RPs,9q4Cit4zAyGeDqKNTaVxhh8B6ZyyEExJJY9HsVz7TUd9,7KpJx7rMPLMVXDsy8iDPRJNgmWBrgcoRMTFDeB9G6Yh2,8VFW82jn3qDhK2MuAZ8Pf3ZMmZ93a7JHCnLdGiGVftDX,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "9MV6Ajs4s8U9vmFU7ZdPS3uqXFqZsafuQfg4ycAykcKr",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=9MV6Ajs4s8U9vmFU7ZdPS3uqXFqZsafuQfg4ycAykcKr&amount=1&index=92&proof=4qNoK9pn82gYzj3YwCNan5zAf8fh7o66FGsyBvSXiDJx,6TJ1Jjus5X7KU1NvLZxABVc8tZ476jhC9TbdZxBm62gR,A7i8YQKvDgGaDCA8baaVRK3i3gd57XsoYBY17wVm9F9S,8VFW82jn3qDhK2MuAZ8Pf3ZMmZ93a7JHCnLdGiGVftDX,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "B9QRCMEwhhv1opMTrp1R9Vv37iw71RcHqn35cy312prr",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=B9QRCMEwhhv1opMTrp1R9Vv37iw71RcHqn35cy312prr&amount=1&index=93&proof=9Kc1RJiwNyF4SeUosYaonjgBSNww18pauxe23kcccfjZ,6TJ1Jjus5X7KU1NvLZxABVc8tZ476jhC9TbdZxBm62gR,A7i8YQKvDgGaDCA8baaVRK3i3gd57XsoYBY17wVm9F9S,8VFW82jn3qDhK2MuAZ8Pf3ZMmZ93a7JHCnLdGiGVftDX,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "FP9624umv6iG5cjMGabhSVayNjYuUnKi9Svz3bBqbvU3",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=FP9624umv6iG5cjMGabhSVayNjYuUnKi9Svz3bBqbvU3&amount=1&index=94&proof=DNQr6jeTQf5eP1GkQrAix52g3ikGrFiVjb8e6cvFhgAf,FofFgvnLfoTMLxRRM7HweoZYAiawUhbS5upr6YonHLsq,A7i8YQKvDgGaDCA8baaVRK3i3gd57XsoYBY17wVm9F9S,8VFW82jn3qDhK2MuAZ8Pf3ZMmZ93a7JHCnLdGiGVftDX,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "DPgvzeXspT7p7Y1QPsmXzch6jpYixiQgF2vLCSypS56i",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=DPgvzeXspT7p7Y1QPsmXzch6jpYixiQgF2vLCSypS56i&amount=1&index=95&proof=Fc4rVzkP1QUBcL5J3szVrmn6c6gPSGAsVWq2k3kpBixm,FofFgvnLfoTMLxRRM7HweoZYAiawUhbS5upr6YonHLsq,A7i8YQKvDgGaDCA8baaVRK3i3gd57XsoYBY17wVm9F9S,8VFW82jn3qDhK2MuAZ8Pf3ZMmZ93a7JHCnLdGiGVftDX,DKhKg16sy5SrF3X5UYkDVPr63wZo3EPRCuR7ttJkM7YN,2kiGZfkjtxCSsoB1Jf9NNSVBbbeYF5oLFUvSw2dwM8J4,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "81wkJ1KosGibnwFSrZLUTQk84TbEL6qAurmh11NTToHp",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=81wkJ1KosGibnwFSrZLUTQk84TbEL6qAurmh11NTToHp&amount=1&index=96&proof=FpX8anAj3RmEzXVNvJQL9GVwtzgbnAzHPC96hgxY2om8,DEeH73gUfrp8MKxu8e2UomHKquK9mUHbHQBR7dWiF2XG,C9StSPiiaFwA33n2DFrbYkjBpN9YTXYGFZDRBpiNnnrx,3iiRrwMtnfCD42RnTaYnMoU9CoE5MeJyQzUPXmBtEG9S,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "UdV8gXHhKWadVrETWNPXgUgAJweFT8kRzJSAjx7VRqE",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=UdV8gXHhKWadVrETWNPXgUgAJweFT8kRzJSAjx7VRqE&amount=1&index=97&proof=954UJjaRJgee8WCsXGoVjpfrPzyDpwSFSi6vJPxtzu6o,DEeH73gUfrp8MKxu8e2UomHKquK9mUHbHQBR7dWiF2XG,C9StSPiiaFwA33n2DFrbYkjBpN9YTXYGFZDRBpiNnnrx,3iiRrwMtnfCD42RnTaYnMoU9CoE5MeJyQzUPXmBtEG9S,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "88w9eQeHQTNLnNERPBBAXvGMDhCVtXhcHc9PhcpVQ9WL",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=88w9eQeHQTNLnNERPBBAXvGMDhCVtXhcHc9PhcpVQ9WL&amount=1&index=98&proof=DfbsY9zqEChW5GpYTBtpa2a7uSgmBy7cuZzZ7YwLSCsT,6EU1AYXkSnVvcjhhT6SVrMShNmhcxKqeHSfVorcEaeUJ,C9StSPiiaFwA33n2DFrbYkjBpN9YTXYGFZDRBpiNnnrx,3iiRrwMtnfCD42RnTaYnMoU9CoE5MeJyQzUPXmBtEG9S,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "2tfZBVKAicpzPUJX7Ce8emtYdn3R5RGawdgypoADLSJL",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=2tfZBVKAicpzPUJX7Ce8emtYdn3R5RGawdgypoADLSJL&amount=1&index=99&proof=EggFibVzGQAgFQNd9FFp8mCdvw3W5YLsgtr4KBKhrs4,6EU1AYXkSnVvcjhhT6SVrMShNmhcxKqeHSfVorcEaeUJ,C9StSPiiaFwA33n2DFrbYkjBpN9YTXYGFZDRBpiNnnrx,3iiRrwMtnfCD42RnTaYnMoU9CoE5MeJyQzUPXmBtEG9S,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "3aQpR7m7n3J6yFwNJK2yzTqnCQXUG2RhJxvV4qZJFDNN",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=3aQpR7m7n3J6yFwNJK2yzTqnCQXUG2RhJxvV4qZJFDNN&amount=1&index=100&proof=CxqyzfrxMaJPTnYh4LrKseEfpWyG8Nm7AMjyWKWRMzgr,a6w5ViJf5kV559zmvT8DzB5KTTtyr82mmKSv9pzy2hn,Dqv3JkeXirTZfkHoV3iXaSs8Lxsa8sEjGccCDDDrP8wH,3iiRrwMtnfCD42RnTaYnMoU9CoE5MeJyQzUPXmBtEG9S,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "5wu3tkeAMqvUP1cnXhdUzjsEf6efn2w9BsL9HerfeFU1",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=5wu3tkeAMqvUP1cnXhdUzjsEf6efn2w9BsL9HerfeFU1&amount=1&index=101&proof=988PE9y4sukjeQNASU5nZyCfuVLnk6niGaK2LrnLkd3j,a6w5ViJf5kV559zmvT8DzB5KTTtyr82mmKSv9pzy2hn,Dqv3JkeXirTZfkHoV3iXaSs8Lxsa8sEjGccCDDDrP8wH,3iiRrwMtnfCD42RnTaYnMoU9CoE5MeJyQzUPXmBtEG9S,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "2o5evUGUJsDtirfiQYA13sKCrvfSCauVktyxrTQwoKpP",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=2o5evUGUJsDtirfiQYA13sKCrvfSCauVktyxrTQwoKpP&amount=1&index=102&proof=6oHvg5WFiwL1TkYXnoEQCudefMXcbn7wZZzc2PM7e4WQ,9rDPrvdFq5be3dFj3pNgVc9fu7tfgBAPDrMEhKTCgNVs,Dqv3JkeXirTZfkHoV3iXaSs8Lxsa8sEjGccCDDDrP8wH,3iiRrwMtnfCD42RnTaYnMoU9CoE5MeJyQzUPXmBtEG9S,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "MRZsBmj2asXmxTgeioRDYPXM7izFihGmmktiV2ncCCJ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=MRZsBmj2asXmxTgeioRDYPXM7izFihGmmktiV2ncCCJ&amount=1&index=103&proof=DcZ5iLFbQnCF3PPdroxUMVC8vQUXwEov9u7miUqrXwHc,9rDPrvdFq5be3dFj3pNgVc9fu7tfgBAPDrMEhKTCgNVs,Dqv3JkeXirTZfkHoV3iXaSs8Lxsa8sEjGccCDDDrP8wH,3iiRrwMtnfCD42RnTaYnMoU9CoE5MeJyQzUPXmBtEG9S,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "31Bnf1awbwPMePfRL66Eryj2K4UYPxDtfPg8VBqLzNgY",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=31Bnf1awbwPMePfRL66Eryj2K4UYPxDtfPg8VBqLzNgY&amount=1&index=104&proof=3oL5e9YyvPtYisNpkeqHwN8eN86p5y6BJXTe5KU6vkBX,C4edkhYqtpp7gZQiXK5bH8cDUmEP45JMEvn8gqz1JD4k,3Wtg2t91Xk1BhqBKCHDWuQ7Um1xotbpHVEim4hzft3VG,8C8P4LSpgvF9DpR3hvktCF44tm6iYRPZAiVpmQG4huWK,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "Dh7SQmik8ExFuvAT816HNze3FRAgCoCt1rEkJBDKqc1X",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=Dh7SQmik8ExFuvAT816HNze3FRAgCoCt1rEkJBDKqc1X&amount=1&index=105&proof=3gLSWEJsCiMd6K3dC6RwshJdP91dj4AdWEqBT5ZM9YhD,C4edkhYqtpp7gZQiXK5bH8cDUmEP45JMEvn8gqz1JD4k,3Wtg2t91Xk1BhqBKCHDWuQ7Um1xotbpHVEim4hzft3VG,8C8P4LSpgvF9DpR3hvktCF44tm6iYRPZAiVpmQG4huWK,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "4xBbYdLNBABq7bkCwaN8b9Rs6EhbWCG5p2wQg8zWJeWU",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=4xBbYdLNBABq7bkCwaN8b9Rs6EhbWCG5p2wQg8zWJeWU&amount=1&index=106&proof=5ZjK1YT8TqY1UWKmvFknoNPUQDgi3wQzVSZyXVBnFg2T,mFuFWXhVxuNnYN9HDat2TMDDoWsnsKEiCZJ9FiwXyhb,3Wtg2t91Xk1BhqBKCHDWuQ7Um1xotbpHVEim4hzft3VG,8C8P4LSpgvF9DpR3hvktCF44tm6iYRPZAiVpmQG4huWK,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "6DzGnxyCg7FvA12XSJLrjG7erZqkHtyHRPWC25D45ZUC",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=6DzGnxyCg7FvA12XSJLrjG7erZqkHtyHRPWC25D45ZUC&amount=1&index=107&proof=CMNaw6jdfbSP5e9367PEa6FVhWhEGYnGztqAZxpWCzcj,mFuFWXhVxuNnYN9HDat2TMDDoWsnsKEiCZJ9FiwXyhb,3Wtg2t91Xk1BhqBKCHDWuQ7Um1xotbpHVEim4hzft3VG,8C8P4LSpgvF9DpR3hvktCF44tm6iYRPZAiVpmQG4huWK,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "7Jw9LaGJC4vUcfSvDfqNJA3NwL1GfAEUTLn2VEqFxByb",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=7Jw9LaGJC4vUcfSvDfqNJA3NwL1GfAEUTLn2VEqFxByb&amount=1&index=108&proof=CDtg9UxTjUZBzvp7UzqqfPEgpwpS3czZXcxtvxBqdSF4,4ErPpXmXJXmpLxLb1vnUNp8Nhuj73Aidzw3eaaafBJ7p,8rY6T9RaBo98Kx7JinK4auES5aukCuRYStjmYmnSgFuG,8C8P4LSpgvF9DpR3hvktCF44tm6iYRPZAiVpmQG4huWK,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "86qLcppAi4ybNBQGGT4ECEJgJQGdQfU2JiVx4B3hSzXS",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=86qLcppAi4ybNBQGGT4ECEJgJQGdQfU2JiVx4B3hSzXS&amount=1&index=109&proof=5Qh7t6FxYVdcbQCbaRAFeFR22zYNZeGkTgPyxg6oHG8W,4ErPpXmXJXmpLxLb1vnUNp8Nhuj73Aidzw3eaaafBJ7p,8rY6T9RaBo98Kx7JinK4auES5aukCuRYStjmYmnSgFuG,8C8P4LSpgvF9DpR3hvktCF44tm6iYRPZAiVpmQG4huWK,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "EDTe5S4U5Nkr2ru5E7Bv5gbUXW3hQLUHdy93nJPdWH3m",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=EDTe5S4U5Nkr2ru5E7Bv5gbUXW3hQLUHdy93nJPdWH3m&amount=1&index=110&proof=AyzKZawXkNhHSgwsmaEJD2nXntxBiWkxNPE3ousgoFkT,DNGEpMzNz9YuK9TJmJNEpXWWu6sTY8UYmieZbTyggh6C,8rY6T9RaBo98Kx7JinK4auES5aukCuRYStjmYmnSgFuG,8C8P4LSpgvF9DpR3hvktCF44tm6iYRPZAiVpmQG4huWK,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "2uLrWn2phs5YzBvokUhxfe3Fdj4my1vFZXCcD6HsbJxE",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=2uLrWn2phs5YzBvokUhxfe3Fdj4my1vFZXCcD6HsbJxE&amount=1&index=111&proof=5u7ENYov3VhbUL3m1TCw72SgA3C73Emi1rG9tpSz9PZq,DNGEpMzNz9YuK9TJmJNEpXWWu6sTY8UYmieZbTyggh6C,8rY6T9RaBo98Kx7JinK4auES5aukCuRYStjmYmnSgFuG,8C8P4LSpgvF9DpR3hvktCF44tm6iYRPZAiVpmQG4huWK,9uLfGGUx6YMuus6f9XDaja9wPcopqLeSvuMkrXL4rk9y,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "28cZK2cZ4j1z594o2yN7FyeHGHLZRNC19bjMDwKQphbE",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=28cZK2cZ4j1z594o2yN7FyeHGHLZRNC19bjMDwKQphbE&amount=1&index=112&proof=5cYPuzpW1YVRGhtsUgvQKjayp7A7WAsv916ar4VpaMzu,GNF5PLCdYsW51MAL9VcDNHNPY1p1HejxSEfHaduMy3cp,G529ZZXdjmPc1A8n9m8RyLzhVuJ7XEocNgCDEvMTQXEh,C25AnWcDWQszYpk1dWuTfsMJYkCCvRf3Az9QRZGoo8Dc,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "DuRgHV8B2uPx7Q7gHKuUYGSJQrXi2e8b8MGfX1ymA9dT",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=DuRgHV8B2uPx7Q7gHKuUYGSJQrXi2e8b8MGfX1ymA9dT&amount=1&index=113&proof=5K6TRuRHuDV342RJTgg9MbWfjYFnNnboUSuQKyMvXMw4,GNF5PLCdYsW51MAL9VcDNHNPY1p1HejxSEfHaduMy3cp,G529ZZXdjmPc1A8n9m8RyLzhVuJ7XEocNgCDEvMTQXEh,C25AnWcDWQszYpk1dWuTfsMJYkCCvRf3Az9QRZGoo8Dc,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "F6M2BFESW1fHkRdLLmft5ZcE7H3uK3c6FDwtdt5mJ8Tu",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=F6M2BFESW1fHkRdLLmft5ZcE7H3uK3c6FDwtdt5mJ8Tu&amount=1&index=114&proof=3Wo6ayVnJHWP9nG8ecg1yLCsCmkRZvXuE7LrU2mMprbn,Fs9JYVNF1NMHxo3TCH1YYKS6xb38N7rgsDQPRMy3LcZY,G529ZZXdjmPc1A8n9m8RyLzhVuJ7XEocNgCDEvMTQXEh,C25AnWcDWQszYpk1dWuTfsMJYkCCvRf3Az9QRZGoo8Dc,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "4g3NaQSAGuzkMKkMu6D3aKUGgWw1WugTvVd9XKBjwg6q",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=4g3NaQSAGuzkMKkMu6D3aKUGgWw1WugTvVd9XKBjwg6q&amount=1&index=115&proof=G8BzwRVP1MP13Rvw7k7ejS65UEQstsbxHVU1mKqa3sQr,Fs9JYVNF1NMHxo3TCH1YYKS6xb38N7rgsDQPRMy3LcZY,G529ZZXdjmPc1A8n9m8RyLzhVuJ7XEocNgCDEvMTQXEh,C25AnWcDWQszYpk1dWuTfsMJYkCCvRf3Az9QRZGoo8Dc,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "DtvRNYMDKG2XKMqAnNPz2quee1kLtVpx4pZia7LTQj9y",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=DtvRNYMDKG2XKMqAnNPz2quee1kLtVpx4pZia7LTQj9y&amount=1&index=116&proof=8RDcyeSDc8bGiRFrWFZnpuXj9wJ5GyhregvuWvNpdBVc,E1qckuMoLECVudjxWTs4b4iH3jRHRs58iKuuJTWfWNNr,8njeRFHqYifrnEuEVfQfPT5MgLqtZT9SHuFCmUkMjt2q,C25AnWcDWQszYpk1dWuTfsMJYkCCvRf3Az9QRZGoo8Dc,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "2FiMmNHhHXzhV9EH4uurqZyfAbdRzXFHM6yHXtETbR63",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=2FiMmNHhHXzhV9EH4uurqZyfAbdRzXFHM6yHXtETbR63&amount=1&index=117&proof=3U4abBkSBEmGBzPsgL9EeGZu4Zhz5t6nS2DmaA4ch485,E1qckuMoLECVudjxWTs4b4iH3jRHRs58iKuuJTWfWNNr,8njeRFHqYifrnEuEVfQfPT5MgLqtZT9SHuFCmUkMjt2q,C25AnWcDWQszYpk1dWuTfsMJYkCCvRf3Az9QRZGoo8Dc,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "4evHQdeK3X5wRWhCvxaYCNfbWYxi1JZ2gg6FagBJ52U4",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=4evHQdeK3X5wRWhCvxaYCNfbWYxi1JZ2gg6FagBJ52U4&amount=1&index=118&proof=8QzwGJLxfafgBXxudZ4LRbVmwm16kXxpkwX7ybrKFzNv,3E17i28Jbw2kszZ7DxpKgkiSotdLjPY7xJCBFy6yfspF,8njeRFHqYifrnEuEVfQfPT5MgLqtZT9SHuFCmUkMjt2q,C25AnWcDWQszYpk1dWuTfsMJYkCCvRf3Az9QRZGoo8Dc,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "BeMq5FA6K72yswy86o77XMz8JB1TBiDYjNhNzu1cHQQh",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=BeMq5FA6K72yswy86o77XMz8JB1TBiDYjNhNzu1cHQQh&amount=1&index=119&proof=C1FKWpsgNsDujmwguYbbMAGvQihs3e4xKXNy2CxMDxfu,3E17i28Jbw2kszZ7DxpKgkiSotdLjPY7xJCBFy6yfspF,8njeRFHqYifrnEuEVfQfPT5MgLqtZT9SHuFCmUkMjt2q,C25AnWcDWQszYpk1dWuTfsMJYkCCvRf3Az9QRZGoo8Dc,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "5JBzuL7BLs6puWYEZhSCxaAKbR47tkiAFCwWMLgNgyB9",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=5JBzuL7BLs6puWYEZhSCxaAKbR47tkiAFCwWMLgNgyB9&amount=1&index=120&proof=8a6EXQXRHf1v18qmUx2DecUZJnCknKYrSyxjpXqebZHt,9FAqVvkUDgBQSsNcPFStgv3PaJjwZVFwGNaM5rhhSbEE,H6XjBr7SbmfD9xBoENz27irT5qthcGxnKTSRYpeRxuAc,Hv38GCW7716pvVC7YwysvNwywgNqXqtjAkmaN7rLZXat,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "7Bv51zGJcmExGcHBWyV4ygj5BDjjxoY5aUma71fjQGJ9",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=7Bv51zGJcmExGcHBWyV4ygj5BDjjxoY5aUma71fjQGJ9&amount=1&index=121&proof=EfRMDZLSHWjVWXZHjrhUgVkYrd1hUNQ6sAVLqZe1m4to,9FAqVvkUDgBQSsNcPFStgv3PaJjwZVFwGNaM5rhhSbEE,H6XjBr7SbmfD9xBoENz27irT5qthcGxnKTSRYpeRxuAc,Hv38GCW7716pvVC7YwysvNwywgNqXqtjAkmaN7rLZXat,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "5n2TFCDLwJKSiLs4nMWjGKYk2dhqVEhMnQt1z7m3LWBw",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=5n2TFCDLwJKSiLs4nMWjGKYk2dhqVEhMnQt1z7m3LWBw&amount=1&index=122&proof=3WPHFKGZURkD4uN9mhSWuqCwwapU8jD39tM9wA1ddYe4,G6hEYbyRorUGcMtu9rA2uVU35FvUHKRAeTJtiw6zT6n3,H6XjBr7SbmfD9xBoENz27irT5qthcGxnKTSRYpeRxuAc,Hv38GCW7716pvVC7YwysvNwywgNqXqtjAkmaN7rLZXat,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "DKEy7PbVkKubkfZxiAb6WyLcJoedV8Esq96JyfreXmQm",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=DKEy7PbVkKubkfZxiAb6WyLcJoedV8Esq96JyfreXmQm&amount=1&index=123&proof=8U9vmgGjZW4SNrF7uf3z4NPrVxZnJAksKkXpDQhGCc3K,G6hEYbyRorUGcMtu9rA2uVU35FvUHKRAeTJtiw6zT6n3,H6XjBr7SbmfD9xBoENz27irT5qthcGxnKTSRYpeRxuAc,Hv38GCW7716pvVC7YwysvNwywgNqXqtjAkmaN7rLZXat,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "5ndEKgpoLMsaR5bPT6WHf7qEWc8DmbATeFA9UiyLgQBL",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=5ndEKgpoLMsaR5bPT6WHf7qEWc8DmbATeFA9UiyLgQBL&amount=1&index=124&proof=5jwFu3H9TdWwDwDPDmxsAQrqynZgHZKZDkPuT184kWDG,9sNRPC1uFNUpDyrcTkVfHudA9mjyxaei9yfvoUz4Dz5r,FkpDeEcuaMCgPH79GByyMQMNa1kfT96xV7CbbdZsxR3a,Hv38GCW7716pvVC7YwysvNwywgNqXqtjAkmaN7rLZXat,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "DSJUWUBV9JqzJMSGGRMR2VsWugQNeeCNtXbeAAxCUWxQ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=DSJUWUBV9JqzJMSGGRMR2VsWugQNeeCNtXbeAAxCUWxQ&amount=1&index=125&proof=3Fq9bBFYQ6BUKvoqRMLJHdyLgobr57tDeSL7bW8Ku5dW,9sNRPC1uFNUpDyrcTkVfHudA9mjyxaei9yfvoUz4Dz5r,FkpDeEcuaMCgPH79GByyMQMNa1kfT96xV7CbbdZsxR3a,Hv38GCW7716pvVC7YwysvNwywgNqXqtjAkmaN7rLZXat,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "GxhmT7HtA1mhEZPPmoZ8tPXL5eHhoUSokvaaMjaEykCq",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=GxhmT7HtA1mhEZPPmoZ8tPXL5eHhoUSokvaaMjaEykCq&amount=1&index=126&proof=AnobYhPjhBZaXKsngwSBXyBGUG9nXSWbPvGcmWjaZQu,9Wtqyt6iMgjqgW9BbdXzF5aNiETycGozVFxb8r3BcjRT,FkpDeEcuaMCgPH79GByyMQMNa1kfT96xV7CbbdZsxR3a,Hv38GCW7716pvVC7YwysvNwywgNqXqtjAkmaN7rLZXat,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "VJwftxa8RgyFuFD33RyJQckL3T8NeDuTdunqH63WdR8",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=VJwftxa8RgyFuFD33RyJQckL3T8NeDuTdunqH63WdR8&amount=1&index=127&proof=9qmJge3iK7UigpfbA8ZCrDm3abQY3r3FGVAxpsQHyta5,9Wtqyt6iMgjqgW9BbdXzF5aNiETycGozVFxb8r3BcjRT,FkpDeEcuaMCgPH79GByyMQMNa1kfT96xV7CbbdZsxR3a,Hv38GCW7716pvVC7YwysvNwywgNqXqtjAkmaN7rLZXat,9u5h4RzDJ2bck6rAM65HUSidpqYLfJahuFVdMkSgZ3Xs,95x4FhQ9nU2a7y5NRNMikBHSqvXQ94ZNqioCTpW4dtfH,fJ5V5U5gE4Wo5FepECyAfAmcmfsxp77UYAoZd2xHfsw,HZ4g8X5A6ewLpCnLw7mSsHfCccz3YYvE3RxSM4WpvwL2&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8Gma6Ds3QS9Xjr2Jh6MuKCgH4jiGPTJPb6dA24hmPX2W",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8Gma6Ds3QS9Xjr2Jh6MuKCgH4jiGPTJPb6dA24hmPX2W&amount=1&index=128&proof=38VPFc8zH8KUJesaihrFno3MERvAS4s3wjRskeVEBSNc,5pfYXTSDSSAYZs88KDtuxh8r6oqRydNMS5Zr66cKp9sC,7d7KM9u6ixtbN4A1PVBP4VQuoiR1bJF9hVFaSEjhguQD,AKVCiJ8LPADVjUcwKwVd6iFeYvPEXvaPArMS7S4MwcrF,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "7piGA8Vm6AUxiJmBQuVM3vpCAL9FqcWaraQ1nQkoNU8B",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=7piGA8Vm6AUxiJmBQuVM3vpCAL9FqcWaraQ1nQkoNU8B&amount=1&index=129&proof=DfxJgeH3pmuEGFtSTNS5xfqvjZauiKducz8ioUAmj5SY,5pfYXTSDSSAYZs88KDtuxh8r6oqRydNMS5Zr66cKp9sC,7d7KM9u6ixtbN4A1PVBP4VQuoiR1bJF9hVFaSEjhguQD,AKVCiJ8LPADVjUcwKwVd6iFeYvPEXvaPArMS7S4MwcrF,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "4ySvvCzJzRfWEtwMg8YaAW63etoW5i26HRSJARKYzvn8",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=4ySvvCzJzRfWEtwMg8YaAW63etoW5i26HRSJARKYzvn8&amount=1&index=130&proof=DXMqPAqk1vLJsW9yUXKi1NFUSqsN24kw7hC49zg4RGwi,HXRftU3kwqWoAn261Q5q8tujciUSKrPvn5bU76NPgUcd,7d7KM9u6ixtbN4A1PVBP4VQuoiR1bJF9hVFaSEjhguQD,AKVCiJ8LPADVjUcwKwVd6iFeYvPEXvaPArMS7S4MwcrF,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "9vVjQSJP4eEbqP3kLnEJ14ctteHxDJmmFh9UoEwLRPQz",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=9vVjQSJP4eEbqP3kLnEJ14ctteHxDJmmFh9UoEwLRPQz&amount=1&index=131&proof=3sieVEBCgFPwPqXLxDYuuKPYbcQ3hmQngBKAN4CQe2yb,HXRftU3kwqWoAn261Q5q8tujciUSKrPvn5bU76NPgUcd,7d7KM9u6ixtbN4A1PVBP4VQuoiR1bJF9hVFaSEjhguQD,AKVCiJ8LPADVjUcwKwVd6iFeYvPEXvaPArMS7S4MwcrF,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "FahUpodJx5HoVQDqDoLiXWm22DpS8DkpnMoEUzfrnJuM",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=FahUpodJx5HoVQDqDoLiXWm22DpS8DkpnMoEUzfrnJuM&amount=1&index=132&proof=3ESxJfSMLBzYPHgq6K1zmdQ91eJzGPFZTmhAJorxTf4U,G99XrX3NRcmgbLvXKoHSnmRPPwwkgpzYS7aVd8wJRJsA,8nX9UqACRoaBpCMJGm9wbnhMdpJEkGHZ46RHfK5dTAUn,AKVCiJ8LPADVjUcwKwVd6iFeYvPEXvaPArMS7S4MwcrF,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "AHz8eHebTSmRFiSTpyiGm2YJQsCqeHXgstLEyduNWd8g",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=AHz8eHebTSmRFiSTpyiGm2YJQsCqeHXgstLEyduNWd8g&amount=1&index=133&proof=2KGgG1PcZbGH8uYiMDs75tKPeXnHb59eASe28jZc8Jkk,G99XrX3NRcmgbLvXKoHSnmRPPwwkgpzYS7aVd8wJRJsA,8nX9UqACRoaBpCMJGm9wbnhMdpJEkGHZ46RHfK5dTAUn,AKVCiJ8LPADVjUcwKwVd6iFeYvPEXvaPArMS7S4MwcrF,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "2zmuEzduAVDJT9FmvsUesudF8aF5ZbKXdRSHBBpEDhgJ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=2zmuEzduAVDJT9FmvsUesudF8aF5ZbKXdRSHBBpEDhgJ&amount=1&index=134&proof=GJPbef5bXnUcKfPNXLmndMYbAiVfvX4VheEMd59fF8Wk,6cJYGWBc45D6x1DqqnjPWbc9w29DaHdYnuREm5jLdv43,8nX9UqACRoaBpCMJGm9wbnhMdpJEkGHZ46RHfK5dTAUn,AKVCiJ8LPADVjUcwKwVd6iFeYvPEXvaPArMS7S4MwcrF,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8jUd3hnEa3Z57Uy851BGPAGqttYh9SojYwg2Pr8GPyBZ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8jUd3hnEa3Z57Uy851BGPAGqttYh9SojYwg2Pr8GPyBZ&amount=1&index=135&proof=KEN1tbm57pNNeXcQBERU93ZbMpGjQ18jZP9TqJcLw9f,6cJYGWBc45D6x1DqqnjPWbc9w29DaHdYnuREm5jLdv43,8nX9UqACRoaBpCMJGm9wbnhMdpJEkGHZ46RHfK5dTAUn,AKVCiJ8LPADVjUcwKwVd6iFeYvPEXvaPArMS7S4MwcrF,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "6536qHVBfAHQRxkLzxGjm2FGdYKoiY26LUXbjojb54mX",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=6536qHVBfAHQRxkLzxGjm2FGdYKoiY26LUXbjojb54mX&amount=1&index=136&proof=EJ2qTGYfvYc5h4RrUQBaxWxN3WQ4iXhTZCvXsYiDd3SP,3fiGASj77hHot7TmNWPvcdpxRjK4TK5zxnbA5QysDiev,48sysyPy22a8W8xvkJRYQJ3D5nz31thiyzBaHNuAQKFw,6f5Vg7PVmv3RDxWpG6GqViNLpAuf3Uhrr8GTPywo9DQf,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "HJ7vXzREAduWAFcrAAfsWpqoiqXXxUwPBP6VuYWx4GAa",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=HJ7vXzREAduWAFcrAAfsWpqoiqXXxUwPBP6VuYWx4GAa&amount=1&index=137&proof=BXkMokExV1ZCNQ7oSht4DggZtcftbajesCPvW84PyxET,3fiGASj77hHot7TmNWPvcdpxRjK4TK5zxnbA5QysDiev,48sysyPy22a8W8xvkJRYQJ3D5nz31thiyzBaHNuAQKFw,6f5Vg7PVmv3RDxWpG6GqViNLpAuf3Uhrr8GTPywo9DQf,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "AQQmy69s3u4vGcg1SkPbG2MmyxjHRyhyByfyb69S5cyf",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=AQQmy69s3u4vGcg1SkPbG2MmyxjHRyhyByfyb69S5cyf&amount=1&index=138&proof=GZ6nCCDxPSBZdokKku6v61Px7VnHrEmJkQ3UzEArfxSh,GorDFH5RpMT9YEQSRTHwAL6B4E4egH3Uj3dsDoiqsc1,48sysyPy22a8W8xvkJRYQJ3D5nz31thiyzBaHNuAQKFw,6f5Vg7PVmv3RDxWpG6GqViNLpAuf3Uhrr8GTPywo9DQf,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8ayLefyxSW4XJ3vwQqEw3ikYBV5pSBwA43FFCfQsneJN",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8ayLefyxSW4XJ3vwQqEw3ikYBV5pSBwA43FFCfQsneJN&amount=1&index=139&proof=E92m5k1SztHxsH1z8KaafDDkP7YkpDnjDyWDg83qi1VV,GorDFH5RpMT9YEQSRTHwAL6B4E4egH3Uj3dsDoiqsc1,48sysyPy22a8W8xvkJRYQJ3D5nz31thiyzBaHNuAQKFw,6f5Vg7PVmv3RDxWpG6GqViNLpAuf3Uhrr8GTPywo9DQf,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "46vtPDAXKDmKEJVEtcTK9y7jkpRGDoAbfso5VUR2U9FR",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=46vtPDAXKDmKEJVEtcTK9y7jkpRGDoAbfso5VUR2U9FR&amount=1&index=140&proof=AsRPiTW19DHWMEJWaVJbnkSpRASYZsYe2JgDe155TyVN,6zrawPy9Rs7uazLkL6ZRPZ5gTVNupd9PQnK8nGPupB4h,4BqfQAXicn1NxohfTz3Rkg1xDDXeHRFi2jNvyMteYrQh,6f5Vg7PVmv3RDxWpG6GqViNLpAuf3Uhrr8GTPywo9DQf,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "B92Kqo4yzvUBi3DZJoDfuaEJ8LrArxewd9PDkQR7uZRp",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=B92Kqo4yzvUBi3DZJoDfuaEJ8LrArxewd9PDkQR7uZRp&amount=1&index=141&proof=386kKNgQGb6AkhA4XwzkiX7Wv3GZwaBRoGyqdYrsUqY8,6zrawPy9Rs7uazLkL6ZRPZ5gTVNupd9PQnK8nGPupB4h,4BqfQAXicn1NxohfTz3Rkg1xDDXeHRFi2jNvyMteYrQh,6f5Vg7PVmv3RDxWpG6GqViNLpAuf3Uhrr8GTPywo9DQf,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "CdCHUQber6nHCTH2YwXqDzuSi9GJgE83iGbsVZkGFEfz",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=CdCHUQber6nHCTH2YwXqDzuSi9GJgE83iGbsVZkGFEfz&amount=1&index=142&proof=ACsnmy5rXaWS5KsMsu7sQVtJiarVZa4WAex8xzRJY9aq,C9bZyqqLdDmCb7QkBMhxgvyDZj4B8ZZG3AxSCXE1TBn5,4BqfQAXicn1NxohfTz3Rkg1xDDXeHRFi2jNvyMteYrQh,6f5Vg7PVmv3RDxWpG6GqViNLpAuf3Uhrr8GTPywo9DQf,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "9XjWrZnKnFL6Rb1EG815Vu18vcrNKtTbt6Bn18835gms",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=9XjWrZnKnFL6Rb1EG815Vu18vcrNKtTbt6Bn18835gms&amount=1&index=143&proof=Cbm6b3WKwrSVRcDkf9eXmZ8DQDTNiqmtL38pGictRbdQ,C9bZyqqLdDmCb7QkBMhxgvyDZj4B8ZZG3AxSCXE1TBn5,4BqfQAXicn1NxohfTz3Rkg1xDDXeHRFi2jNvyMteYrQh,6f5Vg7PVmv3RDxWpG6GqViNLpAuf3Uhrr8GTPywo9DQf,4o3kxHKgrhRfeuUY1y76MvgiJgAytqd73UoVf5bPq5Lu,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "65sWaN1rM3EKUSb2JjTWV3dpPM9AyUgqw4odG8KhMaSo",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=65sWaN1rM3EKUSb2JjTWV3dpPM9AyUgqw4odG8KhMaSo&amount=1&index=144&proof=C8t2egdpyC3FecAdPgDSW4yeyC2xqywkwYQNNXjho4ZV,BFCxYNWvzyxzaoGBovx1mcLE8onFQ2tXv75yhYcMAnqj,6g5co781gPQ6bBABy2BJ7vKch34HCSh1UXbZjTkaq2iQ,39UXtkdffV6wPxgtgujdJwSupdrUuGywY3sff147QpHx,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "7fRNCGpMBU7usckmfyZ7xjiSFBbuY1DMVrb1FvPCbLTG",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=7fRNCGpMBU7usckmfyZ7xjiSFBbuY1DMVrb1FvPCbLTG&amount=1&index=145&proof=C1KRkFga9vpEUNi2pRzpDhuCV5shgofqc3FZ81WWc9wb,BFCxYNWvzyxzaoGBovx1mcLE8onFQ2tXv75yhYcMAnqj,6g5co781gPQ6bBABy2BJ7vKch34HCSh1UXbZjTkaq2iQ,39UXtkdffV6wPxgtgujdJwSupdrUuGywY3sff147QpHx,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "DZtP2UycfmdrYgsaYQBFsnK8pFLWhKqdThRjCKdtiv2t",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=DZtP2UycfmdrYgsaYQBFsnK8pFLWhKqdThRjCKdtiv2t&amount=1&index=146&proof=FT2hUftobwMtA6bS1QvY8kCqvaP5yzzzfgZmftia4mao,4tREujNwzVTb3FyeiK6koCTAYqBhbxyHFe7VUUXVGyKx,6g5co781gPQ6bBABy2BJ7vKch34HCSh1UXbZjTkaq2iQ,39UXtkdffV6wPxgtgujdJwSupdrUuGywY3sff147QpHx,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8AxAUiC39AfwRQH6avYcNvpCn8BfGa4tVnSMdVCc9r96",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8AxAUiC39AfwRQH6avYcNvpCn8BfGa4tVnSMdVCc9r96&amount=1&index=147&proof=GMa6LWdKvUn7vfdXmEjCEfXH638okDgAJAaxgg67oLR1,4tREujNwzVTb3FyeiK6koCTAYqBhbxyHFe7VUUXVGyKx,6g5co781gPQ6bBABy2BJ7vKch34HCSh1UXbZjTkaq2iQ,39UXtkdffV6wPxgtgujdJwSupdrUuGywY3sff147QpHx,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "2LFKBJGzSsaeDvVbHcqKm269HGP5Vfu3VH9FHUyEx85s",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=2LFKBJGzSsaeDvVbHcqKm269HGP5Vfu3VH9FHUyEx85s&amount=1&index=148&proof=7SCVXMNGR1u35dkkqqfRCzGemAWNafrrkV7Fp4sbMKny,4VvukGMH32NRp8T34PPrpufF9R3zPQ92t4BLi1rJjYre,EyDrfwgz1TN2vn2LdzQXbmGE3usxhD5S9z5oAJzzeQVw,39UXtkdffV6wPxgtgujdJwSupdrUuGywY3sff147QpHx,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "3xud9o35LvaGxY71n9iGwWmv8fdWqHKhTMaW7nyYTxJg",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=3xud9o35LvaGxY71n9iGwWmv8fdWqHKhTMaW7nyYTxJg&amount=1&index=149&proof=AbXm1QskQKVBxhn3gb8wSDejmfNHQikxtnDRXLro39q1,4VvukGMH32NRp8T34PPrpufF9R3zPQ92t4BLi1rJjYre,EyDrfwgz1TN2vn2LdzQXbmGE3usxhD5S9z5oAJzzeQVw,39UXtkdffV6wPxgtgujdJwSupdrUuGywY3sff147QpHx,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "FUm3mZdUN2wNB7kMzFD9U6mMFnPGEzzxU68hy9Ty4j8Q",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=FUm3mZdUN2wNB7kMzFD9U6mMFnPGEzzxU68hy9Ty4j8Q&amount=1&index=150&proof=3YevMsh3GKmK22ZW3ppSPNiQw8byHMWUKUbGFs1UqVYt,3X28byp4Ym7ePWREjMg3jZ4bR29tmffNmsPK7gVrHvox,EyDrfwgz1TN2vn2LdzQXbmGE3usxhD5S9z5oAJzzeQVw,39UXtkdffV6wPxgtgujdJwSupdrUuGywY3sff147QpHx,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "CmmMqWBkkG7hbNxJubqZ1aLqY9etwiukZ2J5asu2neQ8",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=CmmMqWBkkG7hbNxJubqZ1aLqY9etwiukZ2J5asu2neQ8&amount=1&index=151&proof=BAw69bmxdiDZcs3h5tq1jps9sXsFPQWK7bxvY11DCBrX,3X28byp4Ym7ePWREjMg3jZ4bR29tmffNmsPK7gVrHvox,EyDrfwgz1TN2vn2LdzQXbmGE3usxhD5S9z5oAJzzeQVw,39UXtkdffV6wPxgtgujdJwSupdrUuGywY3sff147QpHx,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "76jc1fi1wxi4v3Vsw98nMM2tP39L1BsGJ73Gm7oPGoDy",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=76jc1fi1wxi4v3Vsw98nMM2tP39L1BsGJ73Gm7oPGoDy&amount=1&index=152&proof=67pxqLva7ui9Vwwd8Jfe2a7K44Lkt4ozHR4J6mbNijPD,BPZSirgHFot7VH6QfEh3jFqik4Z7kM2Cx8kxosdUfBU7,HZoY9NTGJNCKMZA1rd9Xej8JgnPjutoYp45CVCFFRwqh,4ym2SDwDCARajTtvH55navLqYuvoqk4facZJWFYUxdTs,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "91Uv1QH8wEvv7akjErEgwdamx8rQuAkNyB9rET32evkg",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=91Uv1QH8wEvv7akjErEgwdamx8rQuAkNyB9rET32evkg&amount=1&index=153&proof=3b6uVkUEg73L9kuCXLLtjxqGhJuYUL7grNrh8mv66HDM,BPZSirgHFot7VH6QfEh3jFqik4Z7kM2Cx8kxosdUfBU7,HZoY9NTGJNCKMZA1rd9Xej8JgnPjutoYp45CVCFFRwqh,4ym2SDwDCARajTtvH55navLqYuvoqk4facZJWFYUxdTs,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "Hg1b24HFFKmo2dv5hEWPEhCBAxuVep2UTNuKdyiAE5Fn",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=Hg1b24HFFKmo2dv5hEWPEhCBAxuVep2UTNuKdyiAE5Fn&amount=1&index=154&proof=C2XZUsVEWSVMh7ThxAoRpQGAcrrSjJ9ZLjdWdsVV1DNx,Bq88nAzpCAUSahj9KCGCGqZpoCr1MHn3EhbAJZ7RvNqg,HZoY9NTGJNCKMZA1rd9Xej8JgnPjutoYp45CVCFFRwqh,4ym2SDwDCARajTtvH55navLqYuvoqk4facZJWFYUxdTs,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "4F7TPSwFmp9AswEuPAZV44Q9EBSMTjzopDxj1Ae7goXb",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=4F7TPSwFmp9AswEuPAZV44Q9EBSMTjzopDxj1Ae7goXb&amount=1&index=155&proof=EQmZXbGLy3ENjFfreLDizcj2zSeTdh2QkzjgUgZWHnLp,Bq88nAzpCAUSahj9KCGCGqZpoCr1MHn3EhbAJZ7RvNqg,HZoY9NTGJNCKMZA1rd9Xej8JgnPjutoYp45CVCFFRwqh,4ym2SDwDCARajTtvH55navLqYuvoqk4facZJWFYUxdTs,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "CnVmBGK8a5P2KJxTZY3EaVyXJqVDwaxTD6GGuHGUjfpk",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=CnVmBGK8a5P2KJxTZY3EaVyXJqVDwaxTD6GGuHGUjfpk&amount=1&index=156&proof=J3invvU7eDh5EqGaxSieL6oQHbRKSXVhZsBc4ZU96GDL,BNQcrKwu9LZL8DLTAfzKkCtwUtaAoo1uBBiZ4DoHZJWZ,9DUsRuPMDTZ5xPjPYmX9Cm7RLxzy2PUHkPYEQ3iqmDWQ,4ym2SDwDCARajTtvH55navLqYuvoqk4facZJWFYUxdTs,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "2VyFYvcCEj9iVtvJtRC8AYbxYAUCxsmZBKgdFRBnZBEw",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=2VyFYvcCEj9iVtvJtRC8AYbxYAUCxsmZBKgdFRBnZBEw&amount=1&index=157&proof=FtfBCAxHwNVQCyPpnJNt5b3PbnksBx56Y7FPRp8zNM5L,BNQcrKwu9LZL8DLTAfzKkCtwUtaAoo1uBBiZ4DoHZJWZ,9DUsRuPMDTZ5xPjPYmX9Cm7RLxzy2PUHkPYEQ3iqmDWQ,4ym2SDwDCARajTtvH55navLqYuvoqk4facZJWFYUxdTs,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "3MR83DxpxBXBF22aXAEEPA651DBPYdEgGU6u6aUrgFKp",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=3MR83DxpxBXBF22aXAEEPA651DBPYdEgGU6u6aUrgFKp&amount=1&index=158&proof=BBUxV4hFCM3Ea6q3n6YYYUJqGfgevmKQnC78UNnKtZL,AUeqGty5dkGQvK4dRBesDaidBAaw6SAj4VxdVvu4jpfz,9DUsRuPMDTZ5xPjPYmX9Cm7RLxzy2PUHkPYEQ3iqmDWQ,4ym2SDwDCARajTtvH55navLqYuvoqk4facZJWFYUxdTs,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "EEy6nbC2iVgpgD5BveBQER76L3GyyfaaqJfop3Kcj995",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=EEy6nbC2iVgpgD5BveBQER76L3GyyfaaqJfop3Kcj995&amount=1&index=159&proof=5BfFWyzpKBpvMhfSNQFdtzUMRwTQ5ngtVmoXxtxqHZT7,AUeqGty5dkGQvK4dRBesDaidBAaw6SAj4VxdVvu4jpfz,9DUsRuPMDTZ5xPjPYmX9Cm7RLxzy2PUHkPYEQ3iqmDWQ,4ym2SDwDCARajTtvH55navLqYuvoqk4facZJWFYUxdTs,D8sxFkn4qtdoZPPbzVkZTvVGYDnFQ35rs6Y8gLiNTtvf,9iWwbBR2gmsBNy6EXT72hyCV64EEwQLBQRqfrFz6vLEm,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "9YtVPMzC6pZCe5rDUFwMYAkZDqwW92hFKgmcA8p2GKy4",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=9YtVPMzC6pZCe5rDUFwMYAkZDqwW92hFKgmcA8p2GKy4&amount=1&index=160&proof=5tCRBbkvcpCotqizoNCggQmiww1Fe3AweqRDNfjJ6K1u,DicSuJadQmysSZgJfsCvoDXTe2qcRAGcVcM1ttQgPCR7,82beYP4cNxnMNG5TC2kRtHLdcRgKHZYFZqPMzNrTecKd,5fBbN4Qz9tUSkuBQVBJivZELqAf8gGNSZeZ5EUYhY1be,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "CcB8eE7ce19HH4ovfmGRdBcyaPRgSVy1ADUxDZnCEkut",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=CcB8eE7ce19HH4ovfmGRdBcyaPRgSVy1ADUxDZnCEkut&amount=1&index=161&proof=5ePfnE8RRLAftvyGDrQNp2eBGoPM8MV9TwMNtEBdkGeN,DicSuJadQmysSZgJfsCvoDXTe2qcRAGcVcM1ttQgPCR7,82beYP4cNxnMNG5TC2kRtHLdcRgKHZYFZqPMzNrTecKd,5fBbN4Qz9tUSkuBQVBJivZELqAf8gGNSZeZ5EUYhY1be,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "56xcRvXkPp5EEszFkuYCUbpxRe51QbktSNgyhRCLwinA",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=56xcRvXkPp5EEszFkuYCUbpxRe51QbktSNgyhRCLwinA&amount=1&index=162&proof=21FrePfAedq9pvDbKSoQtsS3zTJmNaoXk9uPtwYjeYdd,HKrBHhoSNuNrUitE7gQSMtiH9yLkYXJRx9mNjRQqQvRL,82beYP4cNxnMNG5TC2kRtHLdcRgKHZYFZqPMzNrTecKd,5fBbN4Qz9tUSkuBQVBJivZELqAf8gGNSZeZ5EUYhY1be,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "92cyoy6fzeASVZm4NZxng9Df6gtdDxyVFAAbmpZGoYvP",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=92cyoy6fzeASVZm4NZxng9Df6gtdDxyVFAAbmpZGoYvP&amount=1&index=163&proof=FwFg9Hc3Z9phNjNfep4AMfsq4bLVnsjySikdLfPU7RgM,HKrBHhoSNuNrUitE7gQSMtiH9yLkYXJRx9mNjRQqQvRL,82beYP4cNxnMNG5TC2kRtHLdcRgKHZYFZqPMzNrTecKd,5fBbN4Qz9tUSkuBQVBJivZELqAf8gGNSZeZ5EUYhY1be,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "HosET3aj4ho7TqAPoUSR4tiDAqiFDfne1TigjjYezmya",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=HosET3aj4ho7TqAPoUSR4tiDAqiFDfne1TigjjYezmya&amount=1&index=164&proof=82LvnMJSAVrgJLetpkEo3Efv3gBRJxMvDfuuzr1TcikZ,82ktFuYME6peiHa9MecsAzSw9WBLfKNmTD1utLV5ZVr5,FEiBh51BfStJVqW6trACZsJVwcUgEUYCdK8m2PqPR3Qt,5fBbN4Qz9tUSkuBQVBJivZELqAf8gGNSZeZ5EUYhY1be,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "EMEEuttLh7wwqhuztEPPRqEnnRcuXVL2n4hMSuzUuZNH",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=EMEEuttLh7wwqhuztEPPRqEnnRcuXVL2n4hMSuzUuZNH&amount=1&index=165&proof=GCbHCN6p3oHUuaGwQnTdLr1ZLNQCkooxpSDcjQikuDGE,82ktFuYME6peiHa9MecsAzSw9WBLfKNmTD1utLV5ZVr5,FEiBh51BfStJVqW6trACZsJVwcUgEUYCdK8m2PqPR3Qt,5fBbN4Qz9tUSkuBQVBJivZELqAf8gGNSZeZ5EUYhY1be,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "Hktnz9iiWKKCEmKAtRwPUah4DHcatK1sd3VXQLpcnjTU",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=Hktnz9iiWKKCEmKAtRwPUah4DHcatK1sd3VXQLpcnjTU&amount=1&index=166&proof=2VmqZsQni8FgBJGKSd8ivirCEuvPh7FvEf2Mir8DdQio,5GndvJ2w7pfVHCkZZMbJs789VYYXxHAXzbpJYT5UA2tH,FEiBh51BfStJVqW6trACZsJVwcUgEUYCdK8m2PqPR3Qt,5fBbN4Qz9tUSkuBQVBJivZELqAf8gGNSZeZ5EUYhY1be,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "A2m6xXg1bWdfYCv5ZZg7tdMiFzpdNSqikmdovgzuWPJe",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=A2m6xXg1bWdfYCv5ZZg7tdMiFzpdNSqikmdovgzuWPJe&amount=1&index=167&proof=5Ugp7BBmPcAKP2xFykEiLM3bCuChrNZSizZJ6Rw8hGdh,5GndvJ2w7pfVHCkZZMbJs789VYYXxHAXzbpJYT5UA2tH,FEiBh51BfStJVqW6trACZsJVwcUgEUYCdK8m2PqPR3Qt,5fBbN4Qz9tUSkuBQVBJivZELqAf8gGNSZeZ5EUYhY1be,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "6mevjXrtRmz8JC5KKLmnRkevZ45JN4pGCvsbXD2Qb6Qm",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=6mevjXrtRmz8JC5KKLmnRkevZ45JN4pGCvsbXD2Qb6Qm&amount=1&index=168&proof=6bzZT2NnpHfSeG4ndCvK35KJ6gXCn3FMpULkRzW8dySV,6f7PjtxF6XT9t5ZbDFr53cTWDR9WbPi5boseJgYVWEcP,Fn3HCSH23whKgDGiarRosir3MFR7hedtxYuL8TKhuxkM,FNiyCuYbU2fzrxcLcZ2zFy9nJq8ZKHRjUfcWUQzw6bLp,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "39HjwtXnRjNCSCM2KYNpMKyo6rMrpZRtA4wQLYYTZDCy",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=39HjwtXnRjNCSCM2KYNpMKyo6rMrpZRtA4wQLYYTZDCy&amount=1&index=169&proof=CxWSrzYesvAVdCJbsrfZkxkZ4z3PZ9NHwNvdYPwivGxs,6f7PjtxF6XT9t5ZbDFr53cTWDR9WbPi5boseJgYVWEcP,Fn3HCSH23whKgDGiarRosir3MFR7hedtxYuL8TKhuxkM,FNiyCuYbU2fzrxcLcZ2zFy9nJq8ZKHRjUfcWUQzw6bLp,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "64ZvVtQ7pFdkbkxGky4FxJvVyHzjjVgGFfCPSKWQezGU",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=64ZvVtQ7pFdkbkxGky4FxJvVyHzjjVgGFfCPSKWQezGU&amount=1&index=170&proof=wrJYdudPLgtZL9bcp5B2pgYqeDwuCRoXTQeEKtjYAWZ,EgFZfRemhWRieKE6rkvWzNtEhUsqesCNNsV9Qxff7pJ5,Fn3HCSH23whKgDGiarRosir3MFR7hedtxYuL8TKhuxkM,FNiyCuYbU2fzrxcLcZ2zFy9nJq8ZKHRjUfcWUQzw6bLp,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "BE2uyJtCJQv4hKuYS1381i9naMeVjHzE9igAaSvu4RJZ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=BE2uyJtCJQv4hKuYS1381i9naMeVjHzE9igAaSvu4RJZ&amount=1&index=171&proof=59NwuC48EpVQcEhf6zyF2TPUFZJ5AhabT28VtjYWBXcc,EgFZfRemhWRieKE6rkvWzNtEhUsqesCNNsV9Qxff7pJ5,Fn3HCSH23whKgDGiarRosir3MFR7hedtxYuL8TKhuxkM,FNiyCuYbU2fzrxcLcZ2zFy9nJq8ZKHRjUfcWUQzw6bLp,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "9VEjHPd34tWvpG13ogFa2gVCqn8NqExZTZHrRjQ5KaqR",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=9VEjHPd34tWvpG13ogFa2gVCqn8NqExZTZHrRjQ5KaqR&amount=1&index=172&proof=E2UZMxGNi824v4z3MHmmEFbKtSnb2EPEPywrmpuTBhNx,73pcMdLR45HWUNkAftQzbiY1uBUFwK9XtNiRXiVSRfL6,BuL18d6dUytWVVF16zAvawNmi45CKpoeeU6JfE5Eo2WA,FNiyCuYbU2fzrxcLcZ2zFy9nJq8ZKHRjUfcWUQzw6bLp,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "51T916u2XK51SvbrmkY34kR6LBgrWVmCHysk69wADjWL",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=51T916u2XK51SvbrmkY34kR6LBgrWVmCHysk69wADjWL&amount=1&index=173&proof=64GcpxkHu66RYTmnH1ihgCnq4wshs7rhpD7nwNyvbJo5,73pcMdLR45HWUNkAftQzbiY1uBUFwK9XtNiRXiVSRfL6,BuL18d6dUytWVVF16zAvawNmi45CKpoeeU6JfE5Eo2WA,FNiyCuYbU2fzrxcLcZ2zFy9nJq8ZKHRjUfcWUQzw6bLp,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "9v4bHPYWv1TLG9fLsBTusvytsMf9N1K9r3KS698KCHZR",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=9v4bHPYWv1TLG9fLsBTusvytsMf9N1K9r3KS698KCHZR&amount=1&index=174&proof=59gBgXwGQRzP9ZH9235hohTzGEn33XGhBfpGUuwywrXt,7gYTBStdyJCgPChzJCMiqFrhnU3rpT6G4SbA6qAgeobd,BuL18d6dUytWVVF16zAvawNmi45CKpoeeU6JfE5Eo2WA,FNiyCuYbU2fzrxcLcZ2zFy9nJq8ZKHRjUfcWUQzw6bLp,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "EZh568VDcXELz94MMQX9JosF2MUjZVJaT71QTb4RcpTx",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=EZh568VDcXELz94MMQX9JosF2MUjZVJaT71QTb4RcpTx&amount=1&index=175&proof=GsHoaWNLSmDNfoaGDmCtemk6n7XvES2TcDpHFaKEBCUt,7gYTBStdyJCgPChzJCMiqFrhnU3rpT6G4SbA6qAgeobd,BuL18d6dUytWVVF16zAvawNmi45CKpoeeU6JfE5Eo2WA,FNiyCuYbU2fzrxcLcZ2zFy9nJq8ZKHRjUfcWUQzw6bLp,GpxtN6vC6oCFSwg6kHvxRnkKHngNwdoxxcn1bMkZzgv,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "2gaJ5Hqf37vcVTwuaec1553oUTKY9re1GHHk2cCvR79A",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=2gaJ5Hqf37vcVTwuaec1553oUTKY9re1GHHk2cCvR79A&amount=1&index=176&proof=C52kemdZJVGuXNSDSVfuVh68W7MVLmFCAizdLZWGxZP7,7cZ5dpF6MEkemBtZKZ6YAGGXzyj27WUBQU56gVduckoD,9pDininrCPsC4vs6AsA3J6zJ5n9Awgg77cdVA4ghEknd,jZTNd4T25wsZUCVw38nAihn1uqJPN5PHQD3UXVVGLmo,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "CJ15jDso2uFKhViBR3DhmLDtLrCG8iKmHt57qL51Kku1",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=CJ15jDso2uFKhViBR3DhmLDtLrCG8iKmHt57qL51Kku1&amount=1&index=177&proof=2gYDdwLrwmV75cj88FKZypiQNFBRedVJg7CQGVAgMsAw,7cZ5dpF6MEkemBtZKZ6YAGGXzyj27WUBQU56gVduckoD,9pDininrCPsC4vs6AsA3J6zJ5n9Awgg77cdVA4ghEknd,jZTNd4T25wsZUCVw38nAihn1uqJPN5PHQD3UXVVGLmo,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "AfSEgxt9b3F7cBs9wsNhkCWSK3MQ6s9SHVx3LKFTbnQm",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=AfSEgxt9b3F7cBs9wsNhkCWSK3MQ6s9SHVx3LKFTbnQm&amount=1&index=178&proof=5o26TfFi9QZY5YhzH6uBawdXDoNKWuavTgoxsBwaWrzk,8iX78VR9uavRucRqK1bApdUEdYQimg46oaEF9WV2xTKX,9pDininrCPsC4vs6AsA3J6zJ5n9Awgg77cdVA4ghEknd,jZTNd4T25wsZUCVw38nAihn1uqJPN5PHQD3UXVVGLmo,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "2tPtZLahH4yR8bFvHSTFnJQMaksvJHVbgDLoNydXGcqE",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=2tPtZLahH4yR8bFvHSTFnJQMaksvJHVbgDLoNydXGcqE&amount=1&index=179&proof=8QARASsWURNJpweUFxWVCEKUjCHFxc7AG8UsfGH35qwS,8iX78VR9uavRucRqK1bApdUEdYQimg46oaEF9WV2xTKX,9pDininrCPsC4vs6AsA3J6zJ5n9Awgg77cdVA4ghEknd,jZTNd4T25wsZUCVw38nAihn1uqJPN5PHQD3UXVVGLmo,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "HtuQzruU2a6yaCEYvh3BwourzPwmofMJvUQz9GbWQSSQ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=HtuQzruU2a6yaCEYvh3BwourzPwmofMJvUQz9GbWQSSQ&amount=1&index=180&proof=6T8vAepA8YJrTLNQLvXhcgCxa8cfqJ4ssqFNXusv4RKz,XGEgqrN2Y8ovT13sB8FXuU8neMrCNQ717SEqNxGoDVf,Esu2wuCeYSd2Vz9szUtZhh2h48Hh6Gdaq3sxWff7FUio,jZTNd4T25wsZUCVw38nAihn1uqJPN5PHQD3UXVVGLmo,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "6YQbSVhAZqoT9h7nnmQUwQMJs1MvWsSrcETkzs9QTHZj",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=6YQbSVhAZqoT9h7nnmQUwQMJs1MvWsSrcETkzs9QTHZj&amount=1&index=181&proof=2DSHoFPrMBdGj68yZN5qQXopXCPveaYCQC3gQLKv1PZp,XGEgqrN2Y8ovT13sB8FXuU8neMrCNQ717SEqNxGoDVf,Esu2wuCeYSd2Vz9szUtZhh2h48Hh6Gdaq3sxWff7FUio,jZTNd4T25wsZUCVw38nAihn1uqJPN5PHQD3UXVVGLmo,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "AzeeWQPCFYUrVm6QohKjfQuwaPg9QRftKa2vAd96QwHh",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=AzeeWQPCFYUrVm6QohKjfQuwaPg9QRftKa2vAd96QwHh&amount=1&index=182&proof=Cv8f8Xgv9UTF5eQJDyL7kRks7kK1C7UxZ8zeFK5EZe9g,H2BPfrUKJ7eQj19DEZfFZRNL55vuQTVvpGK5NxwY79C2,Esu2wuCeYSd2Vz9szUtZhh2h48Hh6Gdaq3sxWff7FUio,jZTNd4T25wsZUCVw38nAihn1uqJPN5PHQD3UXVVGLmo,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "7HxjVRDP5ZqHtbVVurGNHQmnjxpRdJL8e92KRqShKzve",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=7HxjVRDP5ZqHtbVVurGNHQmnjxpRdJL8e92KRqShKzve&amount=1&index=183&proof=8Rr9Bv74to7FQBi2kAoub3MoV9hbx7bte2HGSPzdmTPp,H2BPfrUKJ7eQj19DEZfFZRNL55vuQTVvpGK5NxwY79C2,Esu2wuCeYSd2Vz9szUtZhh2h48Hh6Gdaq3sxWff7FUio,jZTNd4T25wsZUCVw38nAihn1uqJPN5PHQD3UXVVGLmo,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "5qubdEhjTA8w7t5kKuPNd1B89a6BSU1yE8xyzJ4uY8QE",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=5qubdEhjTA8w7t5kKuPNd1B89a6BSU1yE8xyzJ4uY8QE&amount=1&index=184&proof=C6ynkdmJAQkGnkEkjqUnxNycHg2RptPazraF5HhhRwGu,7aeQx5tjMcBNdqkoewtHZRAnQqP914vdLfp5REcgyrD8,9H1sCiccuLeDh8UREm1R839FAQWwgX1JkQPoQ1LYgwB6,7BL4xmjYrJAY8Zg9FfX3o7BJxy5CRTymz6apFpCQfqZr,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "aa7TQBjxJCVgCmkgi9RcTQG19Y3CuSrCE73mjvVTfMQ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=aa7TQBjxJCVgCmkgi9RcTQG19Y3CuSrCE73mjvVTfMQ&amount=1&index=185&proof=6aZeQGDC4rezUtMy3bQTtVEUSsjGauSSNhw7bjum7Q9h,7aeQx5tjMcBNdqkoewtHZRAnQqP914vdLfp5REcgyrD8,9H1sCiccuLeDh8UREm1R839FAQWwgX1JkQPoQ1LYgwB6,7BL4xmjYrJAY8Zg9FfX3o7BJxy5CRTymz6apFpCQfqZr,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "F4uxDd9xdfajVxAsSpF1Z8UJ6e1WMchLCzKSJbkmjSeJ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=F4uxDd9xdfajVxAsSpF1Z8UJ6e1WMchLCzKSJbkmjSeJ&amount=1&index=186&proof=GMA6kV4RZUjuPP6aHxgSimdSDkHZ5SgvxNWYTqV17Ejh,5Ff6astTrWcbzJ9cFeMrHywraT3m9arHK9mz5VP5Dvip,9H1sCiccuLeDh8UREm1R839FAQWwgX1JkQPoQ1LYgwB6,7BL4xmjYrJAY8Zg9FfX3o7BJxy5CRTymz6apFpCQfqZr,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "3nc8CroqkAotNy58jmQVonE8w8szn3YtezXrNQkankxk",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=3nc8CroqkAotNy58jmQVonE8w8szn3YtezXrNQkankxk&amount=1&index=187&proof=92k7ft1oEe8ETTNtTe49zvx2XeyVVPV7CnUaAUhMhkLn,5Ff6astTrWcbzJ9cFeMrHywraT3m9arHK9mz5VP5Dvip,9H1sCiccuLeDh8UREm1R839FAQWwgX1JkQPoQ1LYgwB6,7BL4xmjYrJAY8Zg9FfX3o7BJxy5CRTymz6apFpCQfqZr,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "CN8kxD9ZynQoauBug7Yfm6twBSM1khd9tiuBRZWH5Jxn",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=CN8kxD9ZynQoauBug7Yfm6twBSM1khd9tiuBRZWH5Jxn&amount=1&index=188&proof=DjgM9HCZF3JtE2JNXzhLZXxd7uA3QZ44xNWLtMrRZuFw,FUyKfjtr8LfaojaiAoZZ2zMF6wbpMMts9UKFs62qAjnf,CC6tB2u9AjADpDoZDvTcinuXgpf4sqBscaUTerXabxP8,7BL4xmjYrJAY8Zg9FfX3o7BJxy5CRTymz6apFpCQfqZr,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "CMuRf1cjNyqQnksJg4TWaGxBDj3C2a4nr3NtwECULsBb",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=CMuRf1cjNyqQnksJg4TWaGxBDj3C2a4nr3NtwECULsBb&amount=1&index=189&proof=8u6HkEF1H9NWe8cbudnrCfDcFi6cRVXmZaZUgZsJh2D4,FUyKfjtr8LfaojaiAoZZ2zMF6wbpMMts9UKFs62qAjnf,CC6tB2u9AjADpDoZDvTcinuXgpf4sqBscaUTerXabxP8,7BL4xmjYrJAY8Zg9FfX3o7BJxy5CRTymz6apFpCQfqZr,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "5JrJR5xqou3cwd8STLuKuiHMDEV9FECPZ1JF5KF2bas",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=5JrJR5xqou3cwd8STLuKuiHMDEV9FECPZ1JF5KF2bas&amount=1&index=190&proof=H8QdnVeqGrxr6RwqoEnofdeZjJtVGFKjrGhkauKmgAQg,FH8wrGYooAcVBMhtu2wuCHLD1RM7y5anyEuHc5L78XK8,CC6tB2u9AjADpDoZDvTcinuXgpf4sqBscaUTerXabxP8,7BL4xmjYrJAY8Zg9FfX3o7BJxy5CRTymz6apFpCQfqZr,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "3Jd7MkWeXNrpD1xjaUUZ9n6WnHFjg3NmAQAuRoTQBpC5",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=3Jd7MkWeXNrpD1xjaUUZ9n6WnHFjg3NmAQAuRoTQBpC5&amount=1&index=191&proof=7abkBbLA1JMdzXzZeAKhPEqZEm46nhH5dJJz2yoAonCu,FH8wrGYooAcVBMhtu2wuCHLD1RM7y5anyEuHc5L78XK8,CC6tB2u9AjADpDoZDvTcinuXgpf4sqBscaUTerXabxP8,7BL4xmjYrJAY8Zg9FfX3o7BJxy5CRTymz6apFpCQfqZr,2KfmjvHdgU2HKN6yqySH5jXy633YmvEDscs7yzbicAmY,GdVk9a42EveMfUJBfifh6q7itGv1dowp9VK2ojzcHng9,2p7WLgPNXKWUWischr6FmYfqcRhKxTk1h6XXQ7VaPUZL,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "3t5FPKfQK1KvdXxnwk2EcvtyyQ2XPVVAA187wbMh279j",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=3t5FPKfQK1KvdXxnwk2EcvtyyQ2XPVVAA187wbMh279j&amount=1&index=192&proof=4MCtn2pVyixsB3FyqB4EnQ2HLY826JPCMaBWtnBYB9fE,Dnusrg4hvoRGWrHnULVtUE1xpAkKEfnmqmNcMjeFQiB6,83zdmDA8daXpuzg8qRwmwxqERQGfG1q7op6vq4Dk4xQw,AJe6v8SkE241FyuTHpjbzcWswiXEeNyJYcLvHDQLvKoe,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "3nk8H6DFSnW3KBCGLzbr8ehnbkaAwGQvwVSvoSzo93K8",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=3nk8H6DFSnW3KBCGLzbr8ehnbkaAwGQvwVSvoSzo93K8&amount=1&index=193&proof=93zqmgQV1QK44eu3FSN9Yh5cVZrEk6x3p32tn6pWGDPX,Dnusrg4hvoRGWrHnULVtUE1xpAkKEfnmqmNcMjeFQiB6,83zdmDA8daXpuzg8qRwmwxqERQGfG1q7op6vq4Dk4xQw,AJe6v8SkE241FyuTHpjbzcWswiXEeNyJYcLvHDQLvKoe,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "6TGELzHUZK5TBKz4wr2zWK5gaedHfSpX7TavBNUN5ASJ",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=6TGELzHUZK5TBKz4wr2zWK5gaedHfSpX7TavBNUN5ASJ&amount=1&index=194&proof=cT12xYg2VxmDeDch5VumN3z8TAC1MYVqaLcbCs9WUp4,Bwr5bY9Ex9FyHhJ6jVXvHJ87NmgQbAv44DCmm1itEsaL,83zdmDA8daXpuzg8qRwmwxqERQGfG1q7op6vq4Dk4xQw,AJe6v8SkE241FyuTHpjbzcWswiXEeNyJYcLvHDQLvKoe,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "DP9T5mwZ6mhZFPWRiYHoPH1frek4bvFEBebnJdmi74DK",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=DP9T5mwZ6mhZFPWRiYHoPH1frek4bvFEBebnJdmi74DK&amount=1&index=195&proof=BzKtca4wqV8a7vsXrRKRac3gTmdQy2MyrGhPremsnomp,Bwr5bY9Ex9FyHhJ6jVXvHJ87NmgQbAv44DCmm1itEsaL,83zdmDA8daXpuzg8qRwmwxqERQGfG1q7op6vq4Dk4xQw,AJe6v8SkE241FyuTHpjbzcWswiXEeNyJYcLvHDQLvKoe,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "4Srpix8w6wuGjYpwMyikJBvvrkcMi2ekHqJghymp6VeC",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=4Srpix8w6wuGjYpwMyikJBvvrkcMi2ekHqJghymp6VeC&amount=1&index=196&proof=52NnbJ1XUG7Fq9qGtQDM7vKMzTJkHFPRxeC51r5ytFLV,AGuXbEnk2j3VznpdEgpNgMaNeSQejoguEaWx72dYM2vJ,FP1JiaRgHJAHG2yf9XSCGEmmttE8La76T1utyeswNCCa,AJe6v8SkE241FyuTHpjbzcWswiXEeNyJYcLvHDQLvKoe,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "E79nEVcsj8C2ej8syURtuwUdNfGr1rBTR3NzTMvNqTYC",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=E79nEVcsj8C2ej8syURtuwUdNfGr1rBTR3NzTMvNqTYC&amount=1&index=197&proof=64ww2Bdxiu6Gq1u3N2Abv6wWPDygZmeevgM9gjmGVteE,AGuXbEnk2j3VznpdEgpNgMaNeSQejoguEaWx72dYM2vJ,FP1JiaRgHJAHG2yf9XSCGEmmttE8La76T1utyeswNCCa,AJe6v8SkE241FyuTHpjbzcWswiXEeNyJYcLvHDQLvKoe,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "ATV81Q9bCWLDhsQSEszc8qqtc2twng2n9bXSqcrMjcEf",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=ATV81Q9bCWLDhsQSEszc8qqtc2twng2n9bXSqcrMjcEf&amount=1&index=198&proof=6jzz8GL2fKSUg81BC9nacAXWJAZrGA2SqgBQt6ztsrsC,61P2tmkN7DXYpZF2VyxTNXvgiEsoGxVNfUgAPeHr6wz4,FP1JiaRgHJAHG2yf9XSCGEmmttE8La76T1utyeswNCCa,AJe6v8SkE241FyuTHpjbzcWswiXEeNyJYcLvHDQLvKoe,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "8yeKDCDRpRRzS1jtQBqz7WpLAizpqWT8bkB3jNNe6Suy",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=8yeKDCDRpRRzS1jtQBqz7WpLAizpqWT8bkB3jNNe6Suy&amount=1&index=199&proof=59TDM12h4rmG9kUXw1NYNXgeTQsNGLAi278L1fExDFUT,61P2tmkN7DXYpZF2VyxTNXvgiEsoGxVNfUgAPeHr6wz4,FP1JiaRgHJAHG2yf9XSCGEmmttE8La76T1utyeswNCCa,AJe6v8SkE241FyuTHpjbzcWswiXEeNyJYcLvHDQLvKoe,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "3sLda5DSvFEKKuw4JGcw2VpF3i4JK1MnxfdVmyxAtCgw",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=3sLda5DSvFEKKuw4JGcw2VpF3i4JK1MnxfdVmyxAtCgw&amount=1&index=200&proof=C1Pthj2FQ1cYcRPQRNnHqmJFfuWVrd3BB8GjiF9SzEc8,BMxB3AyhmzEk9nkfh1rwCXa3pwm99bgJkJaxUtzT2xpr,67yucZ81YbKMWKPkkaRzX5TN3xcaG2iuhSS7z2U5FWoj,kt6qJ3Jpvn3t9y86arX663Hp86BbNBxSh8wpUxRFWN7,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "Hmdga8N2MnBszLq4XxU1nXh2GAaBVQVgKTorkBwoa3FN",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=Hmdga8N2MnBszLq4XxU1nXh2GAaBVQVgKTorkBwoa3FN&amount=1&index=201&proof=FcYwJeKqDbnKvP4Fb6a95JYKxGg37AVcCPX8szSsAWrG,BMxB3AyhmzEk9nkfh1rwCXa3pwm99bgJkJaxUtzT2xpr,67yucZ81YbKMWKPkkaRzX5TN3xcaG2iuhSS7z2U5FWoj,kt6qJ3Jpvn3t9y86arX663Hp86BbNBxSh8wpUxRFWN7,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "ExSseCKU1Gk5GxDEonZE9TNBMYrWiKQEwafF2v28iyRz",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=ExSseCKU1Gk5GxDEonZE9TNBMYrWiKQEwafF2v28iyRz&amount=1&index=202&proof=2QV9dDnEkZehfwyCs6nYZrb6XTWY9L4povscXndoxC7M,5pSu5nWjgT8Agq8KBquJU5SvhouRypLnidsjTCPkQNfF,67yucZ81YbKMWKPkkaRzX5TN3xcaG2iuhSS7z2U5FWoj,kt6qJ3Jpvn3t9y86arX663Hp86BbNBxSh8wpUxRFWN7,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "5dbHJWrQraZf55pf3s75XqjU6p4fbdfkkUHAnW9LUy8Z",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=5dbHJWrQraZf55pf3s75XqjU6p4fbdfkkUHAnW9LUy8Z&amount=1&index=203&proof=EgCySF52otGKC3JwmeH2SNgpe9LfmYeTHxa8v3HWQY8k,5pSu5nWjgT8Agq8KBquJU5SvhouRypLnidsjTCPkQNfF,67yucZ81YbKMWKPkkaRzX5TN3xcaG2iuhSS7z2U5FWoj,kt6qJ3Jpvn3t9y86arX663Hp86BbNBxSh8wpUxRFWN7,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "95JCnDcCNTdvXzXdNvUKirTXiKAQ1TwRs4zH7rNYvCzm",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=95JCnDcCNTdvXzXdNvUKirTXiKAQ1TwRs4zH7rNYvCzm&amount=1&index=204&proof=BTpyM9ttduEgVat58z2bBjrwH3nGELJjbopihkag6pSy,4mqVH4RnVXsQYACyeVv4z2FRKGsSdC7qk5yY96fqHUTo,kt6qJ3Jpvn3t9y86arX663Hp86BbNBxSh8wpUxRFWN7,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    },
    {
        "handle": "Fiq52y3Z3rYjrya8xpSJPjWpDbJVs4FF7Rh6zCaCHTDg",
        "amount": 1,
        "url": "https://gumdrop.metaplex.com//claim?distributor=Emp6amKp1EgQCmypEkMkX5gKZs5j8oECD1Nvmkzk1gcJ&method=wallets&handle=Fiq52y3Z3rYjrya8xpSJPjWpDbJVs4FF7Rh6zCaCHTDg&amount=1&index=205&proof=9fyW4UU32hd92aD49vJA2x4uiAxQaAgjcxw3mN7DsTT4,4mqVH4RnVXsQYACyeVv4z2FRKGsSdC7qk5yY96fqHUTo,kt6qJ3Jpvn3t9y86arX663Hp86BbNBxSh8wpUxRFWN7,EuLKxkWf8AMaEBAVVeHz4SFDXskfT77BdEi8DTuwAsq6,5uvnUuPuVYArgosnGah8FGg9XdhNfyieZdYDeZYeccPZ&pin=NA&candy=BBQdSgB4XzvPtomg8rSYJ66YehKAtUsDxuDAPikZvLWi&tokenAcc=44oQstNC8F9EB12GkXJdhKnFHEHFpG5qSUNY6VHX9PU9"
    }
]