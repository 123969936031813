import React from "react";
import partyPopper from "../assets/images/party-popper.png";

export interface HomeHeaderProps {
  children: React.ReactNode;
}

// @todo add lg: breakpoint for desktop
const HomeHeader = (props: HomeHeaderProps) => (
  <div className="h-full w-full flex flex-col">
    {/* <img src={banner} alt="banner" /> */}
    <div className="bg-cover bg-[url('assets/images/banner.jpg')] flex-1"></div>
    <ul className="font-['Kanit'] text-[#DEDDF4] mt-8 flex flex-col place-items-center space-y-4 flex-1">
      <li>
        <img width={64} height={64} src={partyPopper} alt="partyPopper" />
      </li>
      <li className="text-3xl font-medium">Digital Ventures</li>
      <li className="text-3xl font-medium">
        6<sup>th</sup> Anniversary
      </li>
      <li className="text-md">Claim your NFT today to win lucky draw! 🎁</li>
    </ul>
    <div className="flex flex-col justify-end mt-8 mb-8 flex-1">
      {props.children}
    </div>
  </div>
);

export default HomeHeader;
