{
  "name": "@civic/solana-gateway-react",
  "version": "0.9.0",
  "main": "./dist/cjs/index.js",
  "module": "./dist/esm/index.js",
  "types": "./dist/esm/index.d.ts",
  "repository": "git@github.com:civicteam/ociv-gatekeeper.git",
  "dependencies": {
    "@civic/common-gateway-react": "^0.2.0",
    "@identity.com/prove-solana-wallet": "0.2.9",
    "@identity.com/solana-gateway-ts": "^0.7.0",
    "@solana/web3.js": "1.39.0"
  },
  "peerDependencies": {
    "react": "^17.0.2",
    "react-dom": "^17.0.2"
  },
  "files": [
    "/dist"
  ],
  "scripts": {
    "lint": "eslint '{src,test}/**/*.ts' --max-warnings 0",
    "lint:fix": "yarn lint --fix",
    "clean": "rm -rf ./dist",
    "build": "yarn clean && yarn build:esm && yarn build:cjs",
    "build:esm": "tsc --noEmit false --outDir dist/esm -p tsconfig.build.json",
    "build:cjs": "tsc --noEmit false --module commonjs --outDir dist/cjs -p tsconfig.build.json",
    "prepare": "yarn build",
    "script": "ts-node -O '{\"module\":\"commonjs\"}' --transpile-only --files"
  },
  "devDependencies": {
    "typescript": "^4.6.4"
  }
}
