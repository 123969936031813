import * as anchor from "@project-serum/anchor";
import {
  Connection,
  SystemProgram,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import whitelist from "./whitelist.json";
import { MerkleTree } from "./merkleTree";
import { SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID } from "../utils";
import { createAssociatedTokenAccountInstruction, TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { getCandyMachineState } from "../candy-machine";
import { bs58 } from "@project-serum/anchor/dist/cjs/utils/bytes";
import { memoizeAsync } from "../functional/memoize";

type WhitelistData = {
  handle: string;
  amount: number;
  url: string;
};

type ClaimData = {
  distributorKey: anchor.web3.PublicKey;
  distributorInfo: any;
  tokenAcc: string;
  amount: number;
  handle: string;
  proof: Array<Buffer>;
  pin: anchor.BN | null;
  index: number;
};

export const GUMDROP_DISTRIBUTOR_ID = new anchor.web3.PublicKey(
  "gdrpGjVffourzkdDRrQmySw4aTHr8a3xmQzzxSwFD1a"
);

const fetchDistributor = async (
  program: anchor.Program,
  distributorStr: string
): Promise<[anchor.web3.PublicKey, any]> => {
  let key;
  try {
    key = new anchor.web3.PublicKey(distributorStr);
  } catch (err) {
    throw new Error(`Invalid distributor key ${err}`);
  }
  const info = await program.account.merkleDistributor.fetch(key);
  return [key, info];
};

const fetchDistributorMemo = memoizeAsync(fetchDistributor);

const signersOf = (instrs: Array<TransactionInstruction>) => {
  const signers = new Set<anchor.web3.PublicKey>();
  for (const instr of instrs) {
    for (const key of instr.keys) if (key.isSigner) signers.add(key.pubkey);
  }
  return [...signers];
};

const partialSignExtra = (
  tx: Transaction,
  expected: Array<anchor.web3.PublicKey>,
  extraSigners: anchor.web3.Keypair[] = []
) => {
  const matching = extraSigners.filter((kp) =>
    expected.find((p) => p.equals(kp.publicKey))
  );
  if (matching.length > 0) {
    tx.partialSign(...matching);
  }
};

async function parseClaimUrl(
  gumdropProgram: anchor.Program,
  url: URL
): Promise<ClaimData> {
  const searchParams = url.searchParams;
  const distributor: string = searchParams.get("distributor") ?? "";
  const tokenAcc: string = searchParams.get("tokenAcc") ?? "";
  const proofStr: string = searchParams.get("proof") ?? "";
  const handle: string = searchParams.get("handle") ?? "";
  const amount: string = searchParams.get("amount") ?? "";
  const index: string = searchParams.get("index") ?? "";
  const pinStr: string = searchParams.get("pin") ?? "";

  const [distributorKey, distributorInfo] = await fetchDistributorMemo(
    gumdropProgram,
    distributor
  );

  const proof =
    proofStr === ""
      ? []
      : proofStr.split(",").map((b) => {
          const ret = Buffer.from(bs58.decode(b));
          if (ret.length !== 32) throw new Error(`Invalid proof hash length`);
          return ret;
        });

  let pin: anchor.BN | null = null;
  if (pinStr !== "NA") {
    try {
      pin = new anchor.BN(pinStr);
    } catch (err) {
      throw new Error(`Could not parse pin ${pinStr}: ${err}`);
    }
  }

  return {
    distributorKey,
    distributorInfo,
    tokenAcc,
    proof,
    handle,
    amount: Number(amount),
    index: Number(index),
    pin,
  } as ClaimData;
}

const chunk = (arr: Buffer, len: number): Array<Buffer> => {
  const chunks: Array<Buffer> = [];
  const n = arr.length;
  let i = 0;

  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }

  return chunks;
};

async function walletKeyOrPda(
  walletKey: anchor.web3.PublicKey,
  handle: string,
  pin: anchor.BN | null,
  seed: anchor.web3.PublicKey
): Promise<[anchor.web3.PublicKey, Buffer[]]> {
  if (pin === null) {
    try {
      const key = new anchor.web3.PublicKey(handle);
      if (!key.equals(walletKey)) {
        throw new Error(
          "Claimant wallet handle does not match connected wallet"
        );
      }
      return [key, []];
    } catch (err) {
      throw new Error(`Invalid claimant wallet handle ${err}`);
    }
  } else {
    const seeds = [
      seed.toBuffer(),
      Buffer.from(handle),
      Buffer.from(pin.toArray("le", 4)),
    ];

    const [claimantPda] = await anchor.web3.PublicKey.findProgramAddress(
      [seeds[0], ...chunk(seeds[1], 32), seeds[2]],
      GUMDROP_DISTRIBUTOR_ID
    );
    return [claimantPda, seeds];
  }
}

const claimDataByWalletKey = async (gumdropProgram: anchor.Program) => {
  return await whitelist.reduce(async (previous, current: WhitelistData) => {
    const previousSync = await previous;
    const claimUrl = new URL(current.url);
    previousSync[current.handle] = await parseClaimUrl(
      gumdropProgram,
      claimUrl
    );
    return previousSync;
  }, Promise.resolve({} as Record<string, ClaimData>));
};

export const claimDataByWalletKeyMemo = memoizeAsync(claimDataByWalletKey);

export async function isWalletInWhitelist(
  gumdropProgram: anchor.Program,
  walletKey: anchor.web3.PublicKey
): Promise<boolean> {
  return (
    (await claimDataByWalletKeyMemo(gumdropProgram))[walletKey.toBase58()] !==
    undefined
  );
}

export async function createClaimTransactionsIfNeeded(
  wallet: anchor.Wallet,
  gumdropProgram: anchor.Program,
  candyMachineKey: anchor.web3.PublicKey,
  rpcHost: string
): Promise<Transaction | null> {
  const connection = new Connection(rpcHost, "confirmed");
  const candyMachine = await getCandyMachineState(
    wallet,
    candyMachineKey,
    connection
  );

  if (!candyMachine) {
    throw new Error(`Not found Candy Machine ${candyMachineKey}`);
  }

  if (!candyMachine.state.whitelistMintSettings) {
    throw new Error("Candy Machine not have whitelistMintSettings");
  }

  const claimData = (await claimDataByWalletKeyMemo(gumdropProgram))[
    wallet.publicKey.toBase58()
  ];

  if (!isWalletInWhitelist(gumdropProgram, wallet.publicKey)) {
    throw new Error(`Wallet: ${wallet.publicKey} is not in whitelist`);
  }

  let tokenAccKey: anchor.web3.PublicKey;
  try {
    tokenAccKey = new anchor.web3.PublicKey(claimData.tokenAcc);
  } catch (err) {
    throw new Error(`Invalid tokenAcc key ${err}`);
  }

  const whitelistMint = candyMachine.state.whitelistMintSettings.mint;
  const [secret, _seeds] = await walletKeyOrPda(
    wallet.publicKey,
    claimData.handle,
    claimData.pin,
    whitelistMint
  );

  const leaf = Buffer.from([
    ...new anchor.BN(claimData.index).toArray("le", 8),
    ...secret.toBuffer(),
    ...whitelistMint.toBuffer(),
    ...new anchor.BN(claimData.amount).toArray("le", 8),
  ]);

  const matches = MerkleTree.verifyClaim(
    leaf,
    claimData.proof,
    Buffer.from(claimData.distributorInfo.root)
  );

  if (!matches) {
    throw new Error("Gumdrop merkle proof does not match");
  }

  const [claimStatus, cbump] = await anchor.web3.PublicKey.findProgramAddress(
    [
      Buffer.from("ClaimStatus"),
      Buffer.from(new anchor.BN(claimData.index).toArray("le", 8)),
      claimData.distributorKey.toBuffer(),
    ],
    GUMDROP_DISTRIBUTOR_ID
  );

  // candy machine mints fit in a single transaction
  const merkleClaim: Array<TransactionInstruction> = [];

  if ((await connection.getAccountInfo(claimStatus)) === null) {
    // atm the contract has a special case for when the temporal key is defaulted
    // (aka always passes temporal check)
    // TODO: more flexible
    const temporalSigner =
      claimData.distributorInfo.temporal.equals(
        anchor.web3.PublicKey.default
      ) || secret.equals(wallet.publicKey)
        ? wallet.publicKey
        : claimData.distributorInfo.temporal;

    const walletTokenKey = await anchor.utils.token.associatedAddress({
      mint: whitelistMint,
      owner: wallet.publicKey,
    });
    if ((await connection.getAccountInfo(walletTokenKey)) === null) {
      merkleClaim.push(
        createAssociatedTokenAccountInstruction(wallet.publicKey, walletTokenKey, wallet.publicKey, whitelistMint)
      );
    }

    merkleClaim.push(
      await gumdropProgram.instruction.claim(
        cbump,
        new anchor.BN(claimData.index),
        new anchor.BN(claimData.amount),
        secret,
        claimData.proof,
        {
          accounts: {
            distributor: claimData.distributorKey,
            claimStatus,
            from: tokenAccKey,
            to: walletTokenKey,
            temporal: temporalSigner,
            payer: wallet.publicKey,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
          },
        }
      )
    );
  }

  const { blockhash, lastValidBlockHeight } = await connection.getLatestBlockhash("singleGossip");

  let claimTx: Transaction | null = null;
  if (merkleClaim !== null && merkleClaim.length !== 0) {
    claimTx = new Transaction({ feePayer: wallet.publicKey, blockhash, lastValidBlockHeight });

    const setupInstrs = merkleClaim;
    const setupSigners = signersOf(setupInstrs);
    console.log(
      `Expecting the following setup signers: ${setupSigners.map((s) =>
        s.toBase58()
      )}`
    );
    claimTx.add(...setupInstrs);
    claimTx.setSigners(...setupSigners);
    partialSignExtra(claimTx, setupSigners);
  }

  return claimTx;
}
