import { Connection } from "@solana/web3.js"
import { Either, right, left } from "fp-ts/Either";

export type ConnectionPool = Array<Connection>;

export function getConnectionFromPool(pool: ConnectionPool): Either<Error, Connection> {
    if (pool.length === 0) {
        return left(new Error("there is no rpc in the pool"));
    }

    const index = Math.floor(Math.random() * pool.length);
    console.log(index);
    return right(pool[index]);
}